import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
//import FolderIcon from '@material-ui/icons/ErrorOutlinedIcon';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { red } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import { BrowserRouter as Router,Redirect, Link } from "react-router-dom";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
//import clsx from 'clsx';
import {Timeline, TimelineEvent} from 'react-event-timeline'
import {queryActivities} from "../../../api/queryActivities";
import {queryLogs} from "../../../api/queryLogs";
import {postActivity} from "../../../api/postActivity";
import {queryPlans} from "../../../api/queryMarketingPlans";
import {postInvoice} from '../../../api/postInvoice';
import {toCurrency, toCurrencyNumber, localeParseFloat, slugify, parseQueryString} from "../../utility";
import NewInvoice from "../../NewInvoice";
import {fetchSinglePartner} from "../../../api/partners";
import MultipleSelect from './../../MultipleSelect';

const styles = makeStyles( theme => ({
	logInBox: {
		padding:'20px',
		textAlign:'center',
		margin:'0 auto'
	},
	button: {
		background:'#303030',
		padding:'.75rem 1.5rem'
	},
	vCentered: {
		display:'flex',
		alignItems:'center',
		justifyContent:'center',
	},
	root: {
		flexGrow: 1
	},
	paper: {
		textAlign: 'center',
	},
	container: {
		background: '#000000',
	},
	tableCell: {
		fontSize: '16px',
	},
	selectField: {
		marginTop: '8px',
	}

}));

const useStyles = makeStyles( theme => ({
	saveButton: {
		position: 'fixed',
		right: 0,
		bottom: 0,
		background: '#222',
		color: '#fff',
	},
	backButton: {
		position: 'fixed',
		left: 0,
		bottom: 0,
		background: '#222',
		color: '#fff',
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
		padding: "0 5px",
		minHeight: "160px",		
		alignContent: "flex-start",
	},
	chip: {
		margin: 2,
		height: '32px',
		fontSize: '14px'
	},
	formControl: {
		margin: 0,
		width: "100%",
	}, 
	formControlSelect:{
		minHeight: "173px",		
	}

}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

 

class ActivityNew extends React.Component {

	constructor(props) {
		super(props);

		this.queryStrings = parseQueryString(window.location.search.substring(1));
		this.activityNames= props.activityNames;

		this.state = {
			activity: {
				"planId":this.queryStrings.planId,
				"name": {
	              "id": 1,
	              "name": "Demo Equipment"
	            },
				"uId": "",
				"comment": "",
				"time_period": "",
				"cost": 0,
				"campaign_type": 1,
				"company": 1,
				"description": "",
				"target_groups": [],
				"goals": [],
				"invoices": [],
				"status": 1,
				"remainingBudget": 0,
				"billed": 0,
			},
			logs: [],
			companies : [],
			activityPath:false,
		}

		console.log("ActivityNew: construct");

	}

	componentDidMount(){

		console.log("ActivityNew: mounted");
		this.props.loading(true);
		queryPlans(this.state.activity.planId)
		.then((result) => {
				const state = {...this.state};
				this.setState(state);
				this.props.loading(false);
				
		})
		.catch((error) => {
			console.log(error);
			this.props.loading(false);       
		})

		this.getPartnerInfo();

	}

	findCompanyObjectId (companyId) {
		const companyObj = this.state.companies.filter(obj => {
			  return obj.id === parseInt(companyId)
		});

		if(companyObj.length){
			return companyObj[0];
		}
		return null;
	}

	findActivityNameObjectId (activityNameId) {
		const activityNameObj = this.activityNames.filter(obj => {
			  return obj.id === parseInt(activityNameId)
		});

		if(activityNameObj.length){
			return activityNameObj[0];
		}
		return null;
	}


	getPartnerInfo = () => {
		console.log("queryStrings",this.queryStrings);
		
		fetchSinglePartner(this.queryStrings.partnerId)
		.then((result) => {
				console.log("getPartnerInfo", result);
				const companies = result.companies;
				this.setState({companies:companies});
		})
		.catch((error) => {
			console.log(error); 
			console.log("getPartnerInfo", error);       
		})
		
	}

	getActivity = function(activityId = '') {
		const state = {...this.state};	
		const theActivityId = activityId || this.activityId;
		queryActivities(theActivityId)
		.then((result) => {
				state.activity = result;
				state.activity.campaign_type = result.campaign_type.id;
				state.activity.status = result.status.id;							
				state.activity.target_groups = result.target_groups.map(group => group.id);
				state.activity.goals = result.goals.map(goal => goal.id);
				state.activity.company = result.company.name;
				this.setState(state);
				this.getLogs();
		})
		.catch((error) => {
			console.log(error);        
		})

	}

	inputChangeHandler = function(e) {
		const state = {...this.state};
		let inputName = e.target.name;
		let inputValue = e.target.value;
		console.log(inputName,inputValue);

		if( inputName == 'cost'){
			//inputValue = inputValue.replace(",",".");
		}

		if(inputName == "company"){
			state.activity[inputName] = this.findCompanyObjectId(inputValue);

		} else if(inputName == "name"){
			state.activity[inputName] = this.findActivityNameObjectId(inputValue);
		} else {
			state.activity[inputName] = inputValue;
		}

		this.setState(state);
	}

	saveActivity = function(e){
		const activity = {...this.state.activity};
		activity.company = activity.company.id;
		activity.name = activity.name.id;
		activity.goals = activity.goals.map(goal => goal.id);
		activity.target_groups = activity.target_groups.map(group => group.id);
		activity.cost = localeParseFloat(activity.cost);


		postActivity(activity)
		.then((result) => {
			console.log(result)
			this.props.setMessage('Aktivität wurde gespeichert!');
			const activityPath = `/partner/${this.queryStrings.partnerId}/${this.queryStrings.planId}/${result.id}`
			this.setState({activityPath:activityPath});
		})
		.catch((error) => {
			console.log(error.response.data.message);
			let errorMessage = 'Aktivität konnte nicht gespeichert werden!';

			if(error.response.data.message){
				if (typeof error.response.data.message == 'object'){
					Object.keys(error.response.data.message).map(
						(key) => {
							console.log(key);
							errorMessage = errorMessage + " " + error.response.data.message[key];
						}
					);
				} else {
					errorMessage = error.response.data.message;
				}
			}
			
			this.props.setMessage(errorMessage);
			
		})
	}

	updateInvoice(invoice,e){
		let inputName = e.target.name;
		let inputValue = e.target.value;
		console.log(e);
		postInvoice(invoice.id,{status: inputValue})
		.then((result)=>{
			console.log(result);
			this.getActivity();
			this.props.setMessage('Rechnungsstatus geändert!');
		}).catch((error)=>{

			this.props.setMessage('Rechnungsstatus konnte nicht geändert werden!');			
			console.log(error);
		})
	}



	render() {
		if(this.state.activityPath){
			return <Redirect to={this.state.activityPath}/>;
		}

		if(Object.keys(this.state.activity).length && this.state.companies){
			console.log(this.state.activity);
			const classes = {styles};
			const activity = this.state.activity;
			const companies = this.state.companies;

			return(
				<div>
					<h1>{(activity.company && activity.company.name) ? activity.company.name  : null}</h1>        
					<form className={classes.container} noValidate autoComplete="off">
						<Grid container spacing={5} >
							<Grid container item xs={12} style={{justifyContent: 'space-between'}}>
						

								<TextField
									id="company"
									name="company"
									select
									label="Unternehmen"
									className={classes.textField}
									value={activity.company.id}
									SelectProps={{
										native: true,
										MenuProps: {
											className: classes.menu,
										},
									}}
									margin="normal"
									onChange={(e) => this.inputChangeHandler(e)}
								 >
								 <option key="company-0" value={0} disabled selected>Bitte auswählen</option>
								{companies.map((cmp,index) => <option key={`company-${cmp.id}`} value={cmp.id}>{cmp.name}</option>)}
								</TextField>


								<TextField
									id="campaign_type"
									name="campaign_type"
									select
									label="Typ"
									className={classes.textField}
									value={activity.campaign_type}
									SelectProps={{
										native: true,
										MenuProps: {
											className: classes.menu,
										},
									}}
									margin="normal"
									onChange={(e) => this.inputChangeHandler(e)}
								 >
									{this.props.activityTypes.map((obj) => <option key={`activityType-${obj.id}`} value={obj.id}>{obj.name}</option>)}
								</TextField>

								<TextField
									id="time_period"
									name="time_period"
									label="Quartal"
									placeholder="z.B. Q2/2019"
									className={classes.textField}
									value={activity.time_period}
									margin="normal"
									onChange={(e) => this.inputChangeHandler(e)}                  
								/>

								<TextField
									id="name"
									name="name"
									select
									label="Aktivität Bezeichnung"
									className={classes.textField}
									value={activity.name.id}
									SelectProps={{
										native: true,
										MenuProps: {
											className: classes.menu,
										},
									}}
									margin="normal"
									onChange={(e) => this.inputChangeHandler(e)}
								 >
								{this.activityNames.map((obj) => <option key={`activityName-${obj.id}`} value={obj.id}>{obj.name}</option>)}
								</TextField>

								

								<TextField
									required
									
									id="cost"
									name="cost"
									label="Kosten (€)"
									placeholder="z.B. 5000"
									className={classes.textField}
									value={activity.cost}
									margin="normal"
									onChange={(e) => this.inputChangeHandler(e)}					        
								/>
								<TextField
									id="status"
									name="status"                  
									select
									label="Status"
									className={classes.textField}
									value={activity.status}
									SelectProps={{
										native: true,
										MenuProps: {
											className: classes.menu,
										},
									}}
									margin="normal"
									onChange={(e) => this.inputChangeHandler(e)}                  
								>
									{this.props.status.map((statusObj) => <option key={`status-${statusObj.id}`} value={statusObj.id}>{statusObj.name}</option>)}
								</TextField>
							</Grid>
							<Grid item xs={6} style={ {marginTop:'80px', marginBottom:'40px'} }>
								<MultipleSelect item={activity} name="goals" label="Ziele" mappingData={this.props.goals} style={{marginBottom:'50px'}} changeHandler={(e) => this.inputChangeHandler(e)}/>				      
								<MultipleSelect item={activity} name="target_groups" label="Zielgruppe" mappingData={this.props.targetGroups} changeHandler={(e) => this.inputChangeHandler(e)}/>
							</Grid>
							<Grid item xs={6} style={ {marginTop:'80px', marginBottom:'40px'} }>
								<TextField
									style={{width:'100%', marginBottom: '50px'}}
									label="Beschreibung"
									name="description"									
									multiline={true}
									rows={8}
									rowsMax={12}
									value={activity.description}
									onChange={(e) => this.inputChangeHandler(e)}
								/>				      
								<TextField
									style={{width:'100%'}}
									label="Kommentare"
									name="comment"
									multiline={true}
									rows={8}
									rowsMax={12}
									value={activity.comment}
									onChange={(e) => this.inputChangeHandler(e)}
								/>
							</Grid>
							
						</Grid>
						<SaveButton clickHandler={() => this.saveActivity()} />
						<BackButton linkTo={"/partner/" + this.queryStrings.partnerId + "/" + this.queryStrings.planId}  />
					</form>
				</div>
			)

		}
		return null;
	}
		
}

const SaveButton = (props) => {
	const classes = useStyles(props);  

	return(
			<Button className={classes.saveButton} onClick={(e) => props.clickHandler(e)}>Aktivität Speichern</Button>
	)
}

const BackButton = (props) => {
	const classes = useStyles(props);  
	return(
			<Button component={Link} to={props.linkTo} className={classes.backButton}>Zurück</Button>
	)
}

const Invoices = function(props) {
	const classes = styles();
	const invoices = props.invoices;
	return(
		<React.Fragment>
			<Grid style={{textAlign: "right",marginBottom: "10px"}} item xs={12} >
				<Button onClick={() => props.setModal(<NewInvoice reloadActivity={(val) => props.reloadActivity(val)} mappingData={props.mappingData} setMessage={props.setMessage} activityID={props.activityID} />)}>
					Rechnung hinzufügen
					<AddCircleIcon style={{marginLeft:'10px'}} />
				</Button>
			</Grid>
			<Paper className={classes.root} style={{height: '100%'}}>
				<Table className={classes.table}>
					<TableHead>
						<TableRow>
							<TableCell>Voucher</TableCell>
							<TableCell align="center">Beschreibung</TableCell>
							<TableCell align="center">Betrag</TableCell>
							<TableCell align="center">Status</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							invoices.map(invoice => (
								<TableRow key={invoice.voucher}>
									<TableCell className={classes.tableCell} component="th" scope="row">{invoice.voucher}</TableCell>
									<TableCell className={classes.tableCell} align="center">{invoice.description}</TableCell>
									<TableCell className={classes.tableCell} align="center">{toCurrency(invoice.amount)}</TableCell>
									<TableCell className={classes.tableCell} align="center">  
										<TextField
											id="standard-select-currency-native"
											select
												value={invoice.status.id}
											SelectProps={{
												native: true,
												MenuProps: {
													className: classes.menu,
												},
											}}
											margin="normal"
											onChange={(e) => props.updateInvoice(invoice,e)}
										>
											{props.mappingData.map((value,index) => <option key={`invoiceStatus-${index+1}`} value={index+1}>{value}</option>)}
										</TextField>
									</TableCell>
								</TableRow>						
							))
						}
						
					</TableBody>
				</Table>
			</Paper>
		</React.Fragment>
	)
}



export default ActivityNew;