import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { BrowserRouter as Router,Redirect, Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Chart } from "react-google-charts";
import {queryPartners} from "../../../api/queryPartners";
import {toCurrency, slugify} from "../../utility";
import NewPlan from "../../NewPlan";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import Delete from './../../Delete';

const styles = theme => ({
  logInBox: {
    padding:'20px',
    'text-align':'center',
    margin:'0 auto'
  },
  button: {
    background:'#303030',
    padding:'.75rem 1.5rem'
  },
  vCentered: {
    display:'flex',
    'align-items':'center',
    'justify-content':'center',
  },
  root: {
    flexGrow: 1
  },
  paper: {
    background: '#990000',
    padding: theme.spacing(2),
    'text-align': 'left',
    color: theme.palette.text.secondary,
  },
  left:{
    'text-align':'left'
  },
  right:{
    'text-align':'right'
  },
  topInfo:{
    background:'#efefef'
  },
  green:{
    color: '#00ee00',
  }
});

const useStyles = makeStyles({
  freigegeben: {
    color: '#009900',
  },
  beantragt: {
    color: '#eeaa00',
  },  
  abgerechnet: {
    color: '#1dace9',
  },
  backButton: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    background: '#222',
    color: '#fff',
  },
});

class PartnerSingle extends React.Component {

  constructor(props) {
    super(props);
    console.log(props)
    this.partnerId = props.wrapper.match.params.partnerId;
    this.state = {}
    console.log("PartnerSingle", props);
    
  }

 
  componentDidMount(){
    this.fetchData();
  }

  fetchData = () => {
    this.props.loading(true);
    const state = {...this.state};
    queryPartners(this.partnerId)
    .then((result) => {
        state.partner = result;
        this.setState(state);
        this.props.loading(false);
    })
    .catch((error) => {
      console.log(error);  
      this.props.loading(false);      
    })
  }

  refetchData () {
     this.fetchData();
  }

  render() {
    const props = this.props;
    console.log("render",props.user);

    if(this.state.partner){
      if(!this.state.partner.plans.length){
        return(
          <div>
            <h1>{this.state.partner.name}</h1>

             { props.user.canAdd ? (
            <div style={{textAlign:"right", padding:"20px 0"}}>
            <Button onClick={() => props.setModal(<NewPlan refetchData={(val) => this.refetchData()} mappingData={props.mappingData} setMessage={props.setMessage} partnerId={this.partnerId} />)}>
              Marketing Plan hinzufügen
              <AddCircleIcon style={{marginLeft:'10px'}} />
            </Button>
          </div>

           ) : null}
            
            <Paper>
              <div style={{padding:"60px 0"}}><h2>{this.state.partner.name} hat noch keine Marketing Pläne!</h2></div>
            </Paper>
          </div>
        )
      }
      return (
        <div>
          <h1>{this.state.partner.name}</h1>

          { props.user.canAdd ? (
            <div style={{textAlign:"right", padding:"20px 0"}}>
            <Button onClick={() => props.setModal(<NewPlan refetchData={(val) => this.refetchData()} mappingData={props.mappingData} setMessage={props.setMessage} partnerId={this.partnerId} />)}>
              Marketing Plan hinzufügen
              <AddCircleIcon style={{marginLeft:'10px'}} />
            </Button>
          </div>

           ) : null}
          
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Plan</TableCell>
                  <TableCell align="center">Zeitraum</TableCell>                  
                  <TableCell align="center">Total</TableCell>
                  <TableCell align="center">Geplant</TableCell>
                  <TableCell align="center">Abgerechnet</TableCell> 
                  <TableCell align="center">vkzDelta</TableCell>                  
                  <TableCell align="center">Aktivitäten</TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <ListMarketingPlans 
                user={this.props.user}
                refetchData={(val) => this.refetchData()} 
                mappingData={props.mappingData} 
                setMessage={props.setMessage} 
                plans={this.state.partner.plans} 
                partnerId ={this.partnerId} 
                setModal={props.setModal} 
                partnerName={this.state.partner.name}
                />
              </TableBody>
            </Table>
          </Paper>
          <BackButton linkTo="/partner"/>
        </div>
      );
    }
    return null;
  }
}

const BackButton = (props) => {
  const classes = useStyles(props);  
  return(
      <Button component={Link} to={props.linkTo} className={classes.backButton}>Zurück</Button>
  )
}

const ListMarketingPlans = (props) => {

  console.log("ListMarketingPlans", props);

  const classes = { styles };
  let plans = props.plans;
  return plans.map( plan => (
    <TableRow key={plan.id}>
      <TableCell component="th" scope="row">
        {props.partnerName} ({plan.year}. Jahr) 
        {props.user.canAdd &&
          <EditIcon 
          style={{marginBottom:"-6px",paddingLeft:"5px"}}
          onClick={() => props.setModal(
            <NewPlan 
              refetchData={props.refetchData} 
              mappingData={props.mappingData} 
              setMessage={props.setMessage} 
              partnerId={props.partnerId}
              planId={plan.id}
              plan={plan}
            />)}
          />
        }
      </TableCell>
      <TableCell align="center">
        {plan.startDate + ' - ' + plan.endDate}
      </TableCell>      
      <TableCell align="center">
        {toCurrency(plan.vkzTotal)}
      </TableCell>
      <TableCell align="center">
        {toCurrency(plan.vkzPlanned)}      
      </TableCell>
      <TableCell align="center">
        {toCurrency(plan.vkzBilled)}      
      </TableCell>
      <TableCell align="center">
        {toCurrency(plan.vkzDelta)}      
      </TableCell>
      <TableCell align="center">
        {plan.activitiesCount}
      </TableCell>
      <TableCell align="right">
        <Button component={Link} to={"/partner/" + props.partnerId + "/" + plan.id} className="yellowButtonDark">Marketing Plan Details</Button>
      </TableCell>

      <TableCell component="th" scope="row" align="left">
        {props.user.canAdd &&
          <Delete item={plan} type="Plan" refetchCallBack={props.refetchData}/>
        }
       </TableCell>
    </TableRow>
  ))
}


export default PartnerSingle;