export function toCurrency(price){
  console.log("toCurrency", price);
  if(!price) { price = 0;};
	return price.toLocaleString('de-DE',{style: 'currency', currency: 'EUR', currencyDisplay: 'symbol'});
}

export function toCurrencyNumber(float){
  if(!float) { float = 0;};
  return float.toLocaleString('de-DE',{style: 'decimal', minimumFractionDigits:2, maximumFractionDigits:2});
}

export function localeParseFloat(localeNumber){
  if(!localeNumber) { localeNumber = 0;};
  var out = [];
  var decimalChar = ",";
  var separatorChar = ".";
  console.log("localeParseFloat",localeNumber);
  localeNumber = localeNumber.toString();

  const dotSeparatorTest = new RegExp("\\.[0-9]{1,2}$");
  const decimalSeparatedWithDot = localeNumber.match(dotSeparatorTest);


  if(decimalSeparatedWithDot){
    decimalChar = ".";
    separatorChar = ",";
  }

  localeNumber.split(decimalChar).map(function(x){
    x = x.replace(separatorChar, "");
    out.push(x);
  })
  out = out.join(".");
  return parseFloat(out);
}

export function slugify(string) {
	const a = 'àáäâãåăæąçćčđďèéěėëêęǵḧìíïîįłḿǹńňñòóöôœøṕŕřßśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;'
	const b = 'aaaaaaaaacccddeeeeeeeghiiiiilmnnnnooooooprrssssttuuuuuuuuuwxyyzzz------'
	const p = new RegExp(a.split('').join('|'), 'g')

	return string.toString().toLowerCase()
	  .replace(/\s+/g, '-') // Replace spaces with -
	  .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
	  .replace(/&/g, '-and-') // Replace & with 'and'
	  .replace(/[^\w\-]+/g, '') // Remove all non-word characters
	  .replace(/\-\-+/g, '-') // Replace multiple - with single -
	  .replace(/^-+/, '') // Trim - from start of text
	  .replace(/-+$/, '') // Trim - from end of text
}


export function parseQueryString(query) {
  var vars = query.split("&");
  var query_string = {};
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    var key = decodeURIComponent(pair[0]);
    var value = decodeURIComponent(pair[1]);
    // If first entry with this name
    if (typeof query_string[key] === "undefined") {
      query_string[key] = decodeURIComponent(value);
      // If second entry with this name
    } else if (typeof query_string[key] === "string") {
      var arr = [query_string[key], decodeURIComponent(value)];
      query_string[key] = arr;
      // If third or later entry with this name
    } else {
      query_string[key].push(decodeURIComponent(value));
    }
  }
  return query_string;
}

