import Page from './Page';
import NewActivity from './NewActivity';
import React from 'react';
import {AppConsumer} from '../../../App';

export const contextWrapper = (props) =>{
  return (
    <AppConsumer>
            { context => ( <Page 
            	user={context.state.user} 
            	goals={context.state.labels.goals} 
            	status={context.state.labels.status} 
            	targetGroups={context.state.labels.targetGroups}
                activityTypes={context.state.labels.activityTypes}      
            	wrapper = {props}
                setMessage = {context.setMessage}
                setModal = {context.setModal} 
                closeModal = {context.setModal} 
                activityNames={context.state.labels.activityNames}
                loading = {context.loading}
            	/> )}
     </AppConsumer>
    );
}

export const contextWrapperNewActivity = (props) =>{
  return (
    <AppConsumer>
            { context => ( <NewActivity 
                user={context.state.user} 
                goals={context.state.labels.goals} 
                status={context.state.labels.status} 
                targetGroups={context.state.labels.targetGroups}
                activityTypes={context.state.labels.activityTypes}      
                wrapper = {props}
                setMessage = {context.setMessage}
                setModal = {context.setModal} 
                closeModal = {context.setModal} 
                activityNames={context.state.labels.activityNames}
                loading = {context.loading}
                /> )}
     </AppConsumer>
    );
}


export const routes = [
	{
		route:'/partner/:partnerSlug/:planId/:activityId',
		exact:true,
		nicename:'Home',
		showInNav:true,
		'container':contextWrapper,
	},
    {
        route:'/new-activity',
        exact:true,
        nicename:'New Activity',
        showInNav:true,
        'container':contextWrapperNewActivity,
    },    
];