import Login from './Login';
import React from 'react';
import {AppConsumer} from '../../../App';

export const contextWrapper = (props) =>{
    console.log("Login contextWrapper", props);
  return (
    <AppConsumer>
            { context => ( <Login 
            	user={context.state.user}
            	updateUser={context.updateUser}
            	goals={context.state.labels.goals}
            	status={context.state.labels.status}
            	targetGroups={context.state.labels.targetGroups}
                activityTypes={context.state.labels.activityTypes}
            	authStatus = {context.state.authStatus}
            	wrapper = {props}
                loading = {context.loading}
                setMeta = {context.setMeta}
                setAppInitialized = {context.setAppInitialized}
            	/> )}
     </AppConsumer>
    );
}

export const routes = [
	{
		route:'/login',
		exact:true,
		showInNav:false,
		nicename:'Log-In',
		'container':contextWrapper
	}
];