import CompanyList from './CompanyList';
import CompanySingle from './CompanySingle';
import CompanyNew from './CompanyNew';
import React from 'react';
import {AppConsumer} from '../../../App';

export const contextWrapper = (props) =>{
  return (
    <AppConsumer>
            { context => ( <CompanyList 
            	user={context.state.user} 
            	goals={context.state.labels.goals} 
            	status={context.state.labels.status} 
            	targetGroups={context.state.labels.targetGroups}
            	wrapper = {props}
                userRoles={context.state.labels.userRoles}
                loading = {context.loading}
                setMessage = {context.setMessage} 
            	/> )}
     </AppConsumer>
    );
}

export const contextWrapperSingle = (props) =>{
  return (
    <AppConsumer>
            { context => ( <CompanySingle 
                user={context.state.user} 
                goals={context.state.labels.goals} 
                status={context.state.labels.status} 
                targetGroups={context.state.labels.targetGroups}
                wrapper = {props}
                userRoles={context.state.labels.userRoles}
                loading = {context.loading}
                setMessage = {context.setMessage} 
                /> )}
     </AppConsumer>
    );
}

export const contextWrapperNew = (props) =>{
  return (
    <AppConsumer>
            { context => ( <CompanyNew 
                user={context.state.user} 
                goals={context.state.labels.goals} 
                status={context.state.labels.status} 
                targetGroups={context.state.labels.targetGroups}
                wrapper = {props}
                userRoles={context.state.labels.userRoles}
                loading = {context.loading}
                setMessage = {context.setMessage} 
                /> )}
     </AppConsumer>
    );
}



export const routes = [
	{
		route:'/company-admin',
		exact:true,
		nicename:'Users',
		showInNav:true,
		'container':contextWrapper,
	},
    {
        route:'/company-admin/:companyId',
        exact:true,
        nicename:'User',
        showInNav:true,
        'container':contextWrapperSingle,
    },
    {
        route:'/new-company',
        exact:true,
        nicename:'User',
        showInNav:true,
        'container':contextWrapperNew,
    }
];