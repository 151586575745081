import { createMuiTheme } from '@material-ui/core/styles';
import indigo from '@material-ui/core/colors/indigo';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';



export default createMuiTheme({
  palette: {
    primary: { light: grey[900], main: grey[900], dark: grey[900] },
    secondary: indigo // Indigo is probably a good match with pink
  },
  typography: {
  	fontFamily: "'Roboto Slab',serif",
    h1:{
    	fontFamily: "'Source Sans Pro',sans-serif",
    	fontWeight:'bold',
      'text-transform':'uppercase',
    },
    h2:{
    	fontFamily: "'Source Sans Pro',sans-serif",
    	fontWeight:'light',
      'text-transform':'uppercase',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        backgroundColor:'transparent',
        border:'1px solid #333',
        'border-radius':0,
        'text-transform':'uppercase',
        fontFamily: "'Source Sans Pro',sans-serif",
        '&:hover': {
          backgroundColor: '#333',
          'color':'#fff',
        }
      }
    }
  }
});