import axios from 'axios';
import config from './apiConfig';

const queryPartners = (partnerID = '') => {

	const partnerIDParam = partnerID ? "/" + partnerID : "";
	var headers = {'Authorization': "Bearer " + localStorage.getItem("unfAccessToken")};


	return new Promise(function(resolve, reject) {
		axios({
		    method: 'GET',
		    url: config.baseUrl + '/api/1.1/partner' + partnerIDParam,
		    headers:headers,
		})
		.then(function (response) {
		    console.log('PARTNERRESPONSE',response);
		    resolve(response.data);
		})
		.catch(function (error) {
		    console.log(error);
		    reject(error);
		});
	});

}

export { queryPartners };
