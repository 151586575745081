import axios from 'axios';
import config from './apiConfig';

export const fetchCompanies = () => {

	var headers = {'Authorization': "Bearer " + localStorage.getItem("unfAccessToken")};

	return new Promise(function(resolve, reject) {
		axios({
		    method: 'GET',
		    url: config.baseUrl + '/api/1.1/company' ,
		    headers:headers,
		})
		.then(function (response) {
			console.log("fetchCompanies",response);
		    resolve(response.data);
		})
		.catch(function (error) {
		    console.log(error);
		    reject(error);
		});
	});
}


export const fetchSingleCompany = (companyId) => {

	var headers = {'Authorization': "Bearer " + localStorage.getItem("unfAccessToken")};

	return new Promise(function(resolve, reject) {
		console.log(config.baseUrl + '/api/1.1/company/' + companyId);
		axios({
		    method: 'GET',
		    url: config.baseUrl + '/api/1.1/company/' + companyId,
		    headers:headers,
		})
		.then(function (response) {
			console.log("fetchSinglePartner",response);
		    resolve(response.data);
		})
		.catch(function (error) {
		    console.log(error);
		    reject(error);
		});
	});
}


export const updateSingleCompany = (companyId, data) => {

	var headers = {'Authorization': "Bearer " + localStorage.getItem("unfAccessToken")};

	return new Promise(function(resolve, reject) {
		console.log(config.baseUrl + '/api/1.1/company/' + companyId);
		axios({
		    method: 'POST',
		    url: config.baseUrl + '/api/1.1/company/' + companyId,
		    headers:headers,
		    data:data,
		})
		.then(function (response) {
			console.log("updatePartner",response);
		    resolve(response.data);
		})
		.catch(function (error) {
		    console.log(error);
		    reject(error);
		});
	});
}


export const createNewCompany = (data) => {

	var headers = {'Authorization': "Bearer " + localStorage.getItem("unfAccessToken")};

	return new Promise(function(resolve, reject) {
		console.log(config.baseUrl + '/api/1.1/company');
		axios({
		    method: 'POST',
		    url: config.baseUrl + '/api/1.1/company',
		    headers:headers,
		    data:data,
		})
		.then(function (response) {
			console.log("createNewCompany",response);
		    resolve(response.data);
		})
		.catch(function (error) {
		    console.log(error);
		    reject(error);
		});
	});
}
