import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { BrowserRouter as Router,Redirect, Link, withRouter } from "react-router-dom";
import Button from '@material-ui/core/Button';
import { queryPartners } from '../../../api/queryPartners';
import {AppConsumer} from'../../../App';
import {toCurrency} from "../../utility";

const styles = theme => ({
  logInBox: {
    padding:'20px',
    'text-align':'center',
    margin:'0 auto'
  },
  button: {
    background:'#303030',
    padding:'.75rem 1.5rem'
  },
  vCentered: {
    display:'flex',
    'align-items':'center',
    'justify-content':'center',
  },
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

 

class Page extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      partners: [],
    }
  } 

  createData(name, campaigns, companies, plans ) {
    return { name, campaigns, companies, plans  };
  }

  slugify(string) {
    const a = 'àáäâãåăæąçćčđďèéěėëêęǵḧìíïîįłḿǹńňñòóöôœøṕŕřßśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;'
    const b = 'aaaaaaaaacccddeeeeeeeghiiiiilmnnnnooooooprrssssttuuuuuuuuuwxyyzzz------'
    const p = new RegExp(a.split('').join('|'), 'g')

    return string.toString().toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '-and-') // Replace & with 'and'
      .replace(/[^\w\-]+/g, '') // Remove all non-word characters
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '') // Trim - from end of text
  }

  componentDidMount(){
    console.log("Partner Übersicht mounted!");
    this.fetchData();
  }

  fetchData () {
      this.props.loading(true);
      queryPartners()
      .then((result) => {
        result.map( (partnerData, index) => {
          const state = {...this.state};
          state.partners.push(partnerData);        
          this.setState(state);
          this.props.loading(false);
        });
      })
      .catch((error) => {
        console.log(error);  
         this.props.loading(false);      
      })
  }
 

// Partnerseite mit Strategie für den Partner

  render() {
    const classes = { styles };
    console.log("Proppies",this.props);
    if(!this.props.authStatus){
      return null;
     }

     console.log("HERE WE GO",this.state.partners);

    return (
       <div>
       <h1>Mitel Partner</h1>
       <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Partner</TableCell>
            <TableCell align="left">Marketing Pläne</TableCell>
            <TableCell align="left">Gesamtbudget</TableCell>
            <TableCell align="left">geplant</TableCell>
            <TableCell align="left">abgerechnet</TableCell>
            <TableCell align="left">zur Verfügung</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.partners.map(partner => (
            <TableRow key={partner.name}>
              <TableCell component="th" scope="row">
                {partner.name}
              </TableCell>
              <TableCell component="th" scope="row">
                {partner.plansCount}
              </TableCell>
              <TableCell component="th" scope="row">
                { (partner.currentBudget) ? toCurrency(partner.currentBudget.vkzTotal) : ""}
              </TableCell>
              <TableCell component="th" scope="row">
                 { (partner.currentBudget) ? toCurrency(partner.currentBudget.vkzPlanned) : ""}
              </TableCell>
              <TableCell component="th" scope="row">
                 { (partner.currentBudget) ? toCurrency(partner.currentBudget.vkzBilled) : ""}
              </TableCell>
              <TableCell component="th" scope="row">
                 { (partner.currentBudget) ? toCurrency(partner.currentBudget.vkzDelta) : ""}
              </TableCell>
              <TableCell align="right">
                <Button component={Link} to={"/partner/" + partner.id} className="yellowButtonDark">Marketing Pläne</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
    </div>
    );
  }
}



export default withRouter(Page);