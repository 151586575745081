import PartnerList from './PartnerList';
import PartnerSingle from './PartnerSingle';
import PartnerNew from './PartnerNew';
import React from 'react';
import {AppConsumer} from '../../../App';

export const contextWrapper = (props) =>{
  return (
    <AppConsumer>
            { context => ( <PartnerList 
            	user={context.state.user} 
            	goals={context.labels.goals} 
            	status={context.labels.status} 
            	targetGroups={context.labels.targetGroups}
            	wrapper = {props}
                userRoles={context.labels.userRoles}
                loading = {context.loading}
                setMessage = {context.setMessage} 
            	/> )}
     </AppConsumer>
    );
}

export const contextWrapperSingle = (props) =>{
  return (
    <AppConsumer>
            { context => ( <PartnerSingle 
                user={context.state.user} 
                goals={context.labels.goals} 
                status={context.labels.status} 
                targetGroups={context.labels.targetGroups}
                wrapper = {props}
                userRoles={context.labels.userRoles}
                loading = {context.loading}
                setMessage = {context.setMessage} 
                /> )}
     </AppConsumer>
    );
}


export const contextWrapperNew = (props) =>{
  return (
    <AppConsumer>
            { context => ( <PartnerNew 
                user={context.state.user} 
                goals={context.labels.goals} 
                status={context.labels.status} 
                targetGroups={context.labels.targetGroups}
                wrapper = {props}
                userRoles={context.labels.userRoles}
                loading = {context.loading}
                setMessage = {context.setMessage} 
                /> )}
     </AppConsumer>
    );
}


export const routes = [
	{
		route:'/partner-admin',
		exact:true,
		nicename:'Users',
		showInNav:true,
		'container':contextWrapper,
	},
    {
        route:'/partner-admin/:partnerId',
        exact:true,
        nicename:'User',
        showInNav:true,
        'container':contextWrapperSingle,
    },
    {
        route:'/new-partner/',
        exact:true,
        nicename:'Neuer Partner',
        showInNav:true,
        'container':contextWrapperNew,
    },
];