import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
//import FolderIcon from '@material-ui/icons/ErrorOutlinedIcon';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { red } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import { BrowserRouter as Router,Redirect, Link } from "react-router-dom";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
//import clsx from 'clsx';
import {Timeline, TimelineEvent} from 'react-event-timeline'
import {queryActivities} from "../../../api/queryActivities";
import {queryLogs} from "../../../api/queryLogs";
import {postActivity} from "../../../api/postActivity";
import {toCurrency, slugify} from "../../utility";
import usr from "../../../api/users";
import companyApi from "../../../api/company";

const styles = makeStyles( theme => ({
	logInBox: {
		padding:'20px',
		textAlign:'center',
		margin:'0 auto'
	},
	button: {
		background:'#303030',
		padding:'.75rem 1.5rem'
	},
	vCentered: {
		display:'flex',
		alignItems:'center',
		justifyContent:'center',
	},
	root: {
		flexGrow: 1
	},
	paper: {
		textAlign: 'center',
	},
	container: {
		background: '#000000',
	},
	tableCell: {
		fontSize: '16px',
	},
	selectField: {
		marginTop: '8px',
	}

}));

const useStyles = makeStyles( theme => ({
	saveButton: {
		position: 'fixed',
		right: 0,
		bottom: 0,
		background: '#222',
		color: '#fff',
	},
	backButton: {
		position: 'fixed',
		left: 0,
		bottom: 0,
		background: '#222',
		color: '#fff',
	},
	inviteButton:{

	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
		padding: "0 5px",
		minHeight: "160px",		
		alignContent: "flex-start",
	},
	chip: {
		margin: 2,
		height: '32px',
		fontSize: '14px'
	},
	formControl: {
		margin: 0,
		width: "100%",
	}, 
	formControlSelect:{
		minHeight: "173px",		
	}

}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

 

class UserInvite extends React.Component {

	constructor(props) {
		super(props);
		console.log("UsersSingle props", props);
		this.state = {
			user: {
			  "username": "",
			  "firstname": "",
			  "lastname": "",
			  "company": {
			   },
			  "role": {
			    "id": 2,
			    "name": "Partner"
			  }
			},
			logs: [],
			messages: '',
			displayMessage: false,
			userRoles:props.userRoles,
			companies:[],
		}

		 console.log("UserInvite", props);

	}

	componentDidMount(){
		
		
		this.fetchCompanies();
		console.log("UserInvite mounted");
		

		
	}

	fetchCompanies () {
		const state = {...this.state};
		companyApi.fetchCompanies()
		.then((result) => {
			console.log(result);
			if(result){
				this.setState({companies:result});
				console.log(this.state);
			}
		})
		.catch((error) => {
			console.log("UsersSingle Companies",error);        
		})
	}


	closeMessage = function() {
		this.setState({displayMessage: false});
		this.setState({messages: ''});
	}

	findCompanyObjectId (companyId) {
		const companyObj = this.state.companies.filter(obj => {
			  return obj.id === parseInt(companyId)
		});

		if(companyObj.length){
			return companyObj[0];
		}
		return null;
	}

	inputChangeHandler = (e) => {
		var name = e.target.name;
		var value = e.target.value;

		console.log(name, value);

		var newState = {...this.state};

		if (typeof newState.user[name] !== 'object') {
		    newState.user[name] = value;
		} else {
			console.log("is object");
			if(name == "role"){
				console.log(newState.userRoles, name, value);
				const roleName = newState.userRoles[value-1];
				newState.user[name].id = newState.userRoles.indexOf(roleName) + 1;
				newState.user[name].name = roleName
			}
			else if (name == "company"){
				console.log("I found it",this.findCompanyObjectId(value));
				newState.user[name]= this.findCompanyObjectId(value);
			}
		}
		
		console.log(newState);
		this.setState(newState);
	}

	inviteUser = (e) => {
		console.log(this.state.user);
		const userFromState = this.state.user;
		this.props.loading(true);

		const user = {
			email:userFromState.username,
			firstname:userFromState.firstname,
			lastname:userFromState.lastname,
			company_id:userFromState.company.id,
			role_id:userFromState.role.id,
		}

		usr.inviteUser(user)
		.then((result) => {
			console.log(result);
			if(result){
				this.props.loading(false);
				const message = (
				<SnackbarContent 
				open={this.state.displayMessage} 
				style={{background: '#ddecdf', color: '#009640'}} 
				message={<span id="message-id">Der User wurde eingeladen!</span>}
				 />
			);
			this.setState({messages: message});
			this.setState({displayMessage: true});
			}
		})
		.catch((error) => {
			this.props.loading(false);
			console.log("UsersSingle",error.response);
			const message = (
				<SnackbarContent 
				open={this.state.displayMessage} 
				style={{background: '#fedfe0', color: '#e2373d'}} 
				message={<span id="message-id">{error.response.data.message}</span>}
				 />
			);
			this.setState({messages: message});
			this.setState({displayMessage: true});      
		})
	}


	render() {

		if(!Object.keys(this.state.user).length || !this.state.companies){
			console.log("data missing", this.state.user, this.state.companies);
			return null;
		}


			const classes = {styles};
			const user = this.state.user;
			const companies = this.state.companies;

			if(!this.props.userRoles){
				return null;
			}
		
	
			return(
				<div>
					      
					<form className={classes.container} noValidate autoComplete="off">
						<Grid container spacing={5} xs={6} style={{margin:"0 auto"}}>

							<Grid container item xs={12} style={{textAlign:"center"}}>
								<h1 szyle={{paddingBottom:"0"}}>Laden Sie einen neuen User ein.</h1>
							</Grid>

							<Grid container item xs={12} style={{justifyContent: 'space-between',backgroundColor:'#eee', margin:"40px auto"}}>
								<Grid item xs={6}>
								<TextField
									id="username"
									label="E-Mail"
									name="username"
									className={classes.textField}
									value={user.username}
									margin="normal"
									onChange={(e) => this.inputChangeHandler(e)}  
									style={{maxWidth:"400px", width:"100%"}}           
								/>
								</Grid>
								<Grid item xs={3} >
									<TextField
										id="company"
										name="company"
										select
										label="Unternehmen"
										className={classes.textField}
										value={user.company.id}
										SelectProps={{
											native: true,
											MenuProps: {
												className: classes.menu,
											},
										}}
										margin="normal"
										onChange={(e) => this.inputChangeHandler(e)}
									 >
									 <option key={`company-${0}`} value={0}>kein Unternehmen</option>
									 {companies.map((obj) => <option key={`company-${obj.id}`} value={obj.id}>{obj.name}</option>)}
									</TextField>
								</Grid>

								
								<Grid item xs={3} >
									<TextField
										id="role"
										name="role"
										select
										label="Rolle"
										className={classes.textField}
										value={user.role.id}
										SelectProps={{
											native: true,
											MenuProps: {
												className: classes.menu,
											},
										}}
										margin="normal"
										onChange={(e) => this.inputChangeHandler(e)}
									 >
									 {this.props.userRoles.map((obj) => <option key={`role-${obj.id}`} value={obj.id}>{obj.name}</option>)}
									</TextField>
								</Grid>

								<Grid item xs={6}>
									<TextField
										id="firstname"
										label="Vorname"
										name="firstname"
										className={classes.textField}
										value={user.firstname}
										margin="normal"
										onChange={(e) => this.inputChangeHandler(e)}   
										style={{maxWidth:"400px", width:"100%"}}               
									/>
								</Grid>

								<Grid item xs={6}>
									<TextField
										id="lastname"
										label="Nachname"
										name="lastname"
										className={classes.textField}
										value={user.lastname}
										margin="normal"
										style={{maxWidth:"400px", width:"100%"}} 
										onChange={(e) => this.inputChangeHandler(e)}                  
									/>
								</Grid>

								<Grid item xs={12} style={{margin:"40px 0 0 0"}}>
								<SaveButton clickHandler={() => this.inviteUser()} />
								</Grid>
							</Grid>
							
							<Grid container item  style={{justifyContent:'flex-start'} }>
								<Grid item xs={6} style={ {padding:'0 20px 0 0',justifyContent:'flex-start'} }>
									<Paper className={classes.root} style={{width:'100%', marginTop: '48px'}}>
										
									</Paper>
								</Grid>
								
							</Grid>
						</Grid>
						
						<BackButton />
					</form>
		      <Snackbar
		        anchorOrigin={{
		          vertical: 'bottom',
		          horizontal: 'center',
		        }}
		        open={this.state.displayMessage}
		        onClose={(e) => this.closeMessage(e)}
		        autoHideDuration={3000}
		      >
		      {this.state.messages ? this.state.messages : null}
		      </Snackbar>
				</div>
			)

		

	}
		
}

const SaveButton = (props) => {
	const classes = useStyles(props);  

	return(
			<Button className={classes.inviteButton} onClick={(e) => props.clickHandler(e)}>User einladen</Button>
	)
}

const BackButton = (props) => {
	const classes = useStyles(props);  
	return(
			<Button button to="/users" component={Link} className={classes.backButton}>Zurück</Button>
	)
}



export default UserInvite;