import Page_404 from './Page_404';
import React from 'react';

export const routes = [
	{
		route:'*',
		exact:true,
		showInNav:false,
		'container':Page_404,
	}
];