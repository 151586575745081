import UserList from './UserList';
import UserSingle from './UserSingle';
import UserInvite from './UserInvite';
import AcceptInvitation from './UserAcceptInvitation';
import React from 'react';
import {AppConsumer} from '../../../App';

export const contextWrapper = (props) =>{
  return (
    <AppConsumer>
            { context => ( <UserList 
            	user={context.state.user} 
            	goals={context.state.labels.goals} 
            	status={context.state.labels.status} 
            	targetGroups={context.state.labels.targetGroups}
            	wrapper = {props}
                userRoles={context.state.labels.userRoles}
                loading = {context.loading}
                setMessage = {context.setMessage}
            	/> )}
     </AppConsumer>
    );
}

export const contextWrapperSingle = (props) =>{
  return (
    <AppConsumer>
            { context => ( <UserSingle 
                user={context.state.user} 
                goals={context.state.labels.goals} 
                status={context.state.labels.status} 
                targetGroups={context.state.labels.targetGroups}
                wrapper = {props}
                userRoles={context.state.labels.userRoles}
                loading = {context.loading}
                setMessage = {context.setMessage}
                /> )}
     </AppConsumer>
    );
}

export const contextWrapperInvite = (props) =>{
  return (
    <AppConsumer>
            { context => ( <UserInvite 
                user={context.state.user} 
                goals={context.state.labels.goals} 
                status={context.state.labels.status} 
                targetGroups={context.state.labels.targetGroups}
                wrapper = {props}
                userRoles={context.state.labels.userRoles}
                loading = {context.loading}
                setMessage = {context.setMessage}
                /> )}
     </AppConsumer>
    );
}


export const contextWrapperAcceptInvitation = (props) =>{
  return (
    <AppConsumer>
            { context => ( <AcceptInvitation 
                user={context.state.user} 
                goals={context.state.labels.goals} 
                status={context.state.labels.status} 
                targetGroups={context.state.labels.targetGroups}
                wrapper = {props}
                userRoles={context.state.labels.userRoles}
                loading = {context.loading}
                setMessage = {context.setMessage}
                /> )}
     </AppConsumer>
    );
}

export const routes = [
	{
		route:'/users',
		exact:true,
		nicename:'Users',
		showInNav:true,
		'container':contextWrapper,
	},
    {
        route:'/users/:userId',
        exact:true,
        nicename:'User',
        showInNav:true,
        'container':contextWrapperSingle,
    },
    {
        route:'/new-user/invite',
        exact:true,
        nicename:'User',
        showInNav:true,
        'container':contextWrapperInvite,
    },
     {
        route:'/invitation/accept',
        exact:true,
        nicename:'EInladung akzeptieren',
        showInNav:true,
        'container':contextWrapperAcceptInvitation,
    }
];