import axios from 'axios';
import config from './apiConfig';

export const getMeta = () => {

	var headers = {'Authorization': "Bearer " + localStorage.getItem("unfAccessToken")};

	return new Promise(function(resolve, reject) {
		axios({
		    method: 'GET',
		    url: config.baseUrl + '/api/1.1/meta' ,
		    headers:headers,
		})
		.then(function (response) {
			console.log("getMeta",response);
		    resolve(response.data);
		})
		.catch(function (error) {
		    console.log(error);
		    reject(error);
		});
	});
}


/* GOALS */

export const getGoals = () => {

	var headers = {'Authorization': "Bearer " + localStorage.getItem("unfAccessToken")};

	return new Promise(function(resolve, reject) {
		axios({
		    method: 'GET',
		    url: config.baseUrl + '/api/1.1/goals' ,
		    headers:headers,
		})
		.then(function (response) {
			console.log("getMeta",response);
		    resolve(response.data);
		})
		.catch(function (error) {
		    console.log(error);
		    reject(error);
		});
	});
}

export const deleteGoal = (goalId) => {

	if(!goalId){ return false;}
	var headers = {'Authorization': "Bearer " + localStorage.getItem("unfAccessToken")};

	return new Promise(function(resolve, reject) {
		axios({
		    method: 'DELETE',
		    url: `${config.baseUrl}/api/1.1/goals/${goalId}`,
		    headers:headers,
		})
		.then(function (response) {
			console.log("getMeta",response);
		    resolve(response.data);
		})
		.catch(function (error) {
		    console.log(error);
		    reject(error);
		});
	});
}


export const updateGoal = ( goalId, data ) => {

	if(!goalId){ return false;}
	var headers = {'Authorization': "Bearer " + localStorage.getItem("unfAccessToken")};

	return new Promise(function(resolve, reject) {
		axios({
		    method: 'POST',
		    url: `${config.baseUrl}/api/1.1/goals/${goalId}`,
		    headers:headers,
		    data:data,
		})
		.then(function (response) {
			console.log("getMeta",response);
		    resolve(response.data);
		})
		.catch(function (error) {
		    console.log(error);
		    reject(error);
		});
	});
}

export const newGoal = ( data ) => {

	var headers = {'Authorization': "Bearer " + localStorage.getItem("unfAccessToken")};

	return new Promise(function(resolve, reject) {
		axios({
		    method: 'POST',
		    url: `${config.baseUrl}/api/1.1/goals`,
		    headers:headers,
		    data:data,
		})
		.then(function (response) {
			console.log("getMeta",response);
		    resolve(response.data);
		})
		.catch(function (error) {
		    console.log(error);
		    reject(error);
		});
	});
}



/* ACTIVITY TYPES */

export const getActivityTypes = () => {

	var headers = {'Authorization': "Bearer " + localStorage.getItem("unfAccessToken")};

	return new Promise(function(resolve, reject) {
		axios({
		    method: 'GET',
		    url: config.baseUrl + '/api/1.1/activity-type' ,
		    headers:headers,
		})
		.then(function (response) {
			console.log("getMeta",response);
		    resolve(response.data);
		})
		.catch(function (error) {
		    console.log(error);
		    reject(error);
		});
	});
}

export const deleteActivityType = (activityTypeId) => {

	if(!activityTypeId){ return false;}
	var headers = {'Authorization': "Bearer " + localStorage.getItem("unfAccessToken")};

	return new Promise(function(resolve, reject) {
		axios({
		    method: 'DELETE',
		    url: `${config.baseUrl}/api/1.1/activity-type/${activityTypeId}`,
		    headers:headers,
		})
		.then(function (response) {
			console.log("getMeta",response);
		    resolve(response.data);
		})
		.catch(function (error) {
		    console.log(error);
		    reject(error);
		});
	});
}


export const updateActivityType = ( activityTypeId, data ) => {

	if(!activityTypeId){ return false;}
	var headers = {'Authorization': "Bearer " + localStorage.getItem("unfAccessToken")};

	return new Promise(function(resolve, reject) {
		axios({
		    method: 'POST',
		    url: `${config.baseUrl}/api/1.1/activity-type/${activityTypeId}`,
		    headers:headers,
		    data:data,
		})
		.then(function (response) {
			console.log("getMeta",response);
		    resolve(response.data);
		})
		.catch(function (error) {
		    console.log(error);
		    reject(error);
		});
	});
}

export const newActivityType = ( data ) => {

	var headers = {'Authorization': "Bearer " + localStorage.getItem("unfAccessToken")};

	return new Promise(function(resolve, reject) {
		axios({
		    method: 'POST',
		    url: `${config.baseUrl}/api/1.1/activity-type`,
		    headers:headers,
		    data:data,
		})
		.then(function (response) {
			console.log("getMeta",response);
		    resolve(response.data);
		})
		.catch(function (error) {
		    console.log(error);
		    reject(error);
		});
	});
}


/* TARGET GROUPS */

export const getTargetGroups = () => {

	var headers = {'Authorization': "Bearer " + localStorage.getItem("unfAccessToken")};

	return new Promise(function(resolve, reject) {
		axios({
		    method: 'GET',
		    url: config.baseUrl + '/api/1.1/target-group' ,
		    headers:headers,
		})
		.then(function (response) {
			console.log("getMeta",response);
		    resolve(response.data);
		})
		.catch(function (error) {
		    console.log(error);
		    reject(error);
		});
	});
}

export const deleteTargetGroup = (targetGroupId) => {

	if(!targetGroupId){ return false;}
	var headers = {'Authorization': "Bearer " + localStorage.getItem("unfAccessToken")};

	return new Promise(function(resolve, reject) {
		axios({
		    method: 'DELETE',
		    url: `${config.baseUrl}/api/1.1/target-group/${targetGroupId}`,
		    headers:headers,
		})
		.then(function (response) {
			console.log("getMeta",response);
		    resolve(response.data);
		})
		.catch(function (error) {
		    console.log(error);
		    reject(error);
		});
	});
}


export const updateTargetGroup = ( targetGroupId, data ) => {

	if(!targetGroupId){ return false;}
	var headers = {'Authorization': "Bearer " + localStorage.getItem("unfAccessToken")};

	return new Promise(function(resolve, reject) {
		axios({
		    method: 'POST',
		    url: `${config.baseUrl}/api/1.1/target-group/${targetGroupId}`,
		    headers:headers,
		    data:data,
		})
		.then(function (response) {
			console.log("getMeta",response);
		    resolve(response.data);
		})
		.catch(function (error) {
		    console.log(error);
		    reject(error);
		});
	});
}

export const newTargetGroup = ( data ) => {

	var headers = {'Authorization': "Bearer " + localStorage.getItem("unfAccessToken")};

	return new Promise(function(resolve, reject) {
		axios({
		    method: 'POST',
		    url: `${config.baseUrl}/api/1.1/target-group`,
		    headers:headers,
		    data:data,
		})
		.then(function (response) {
			console.log("getMeta",response);
		    resolve(response.data);
		})
		.catch(function (error) {
		    console.log(error);
		    reject(error);
		});
	});
}