import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { BrowserRouter as Router,Redirect, Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import { queryPartners } from '../../../api/queryPartners';
import {AppConsumer} from'../../../App';
import {toCurrency} from "../../utility";
import {fetchPartners} from "../../../api/partners";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Grid from '@material-ui/core/Grid';
import Delete from './../../Delete';


const styles = theme => ({
  logInBox: {
    padding:'20px',
    'text-align':'center',
    margin:'0 auto'
  },
  button: {
    background:'#303030',
    padding:'.75rem 1.5rem'
  },
  vCentered: {
    display:'flex',
    'align-items':'center',
    'justify-content':'center',
  },
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

 

class PartnerList extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      partners: [],
    }
  } 


  componentDidMount(){

      this.fetchData();
  }

  fetchData = () => {

    console.log("fetchData");
    this.props.loading(true);
    fetchPartners()
    .then((result) => {

        if(result){
          console.log("PartnerList",result);
          this.setState({partners:result});
          this.props.loading(false);
        }
        

    })
    .catch((error) => {
      console.log(error); 
      this.props.loading(false);      
    })
  }
 
// mp jahr (1. jahr + zeitraum / 2. jahr + zeitraum )
//zielgruppen dropdown
// aktivität dropdown
// spalten: kosten / bereits abgerechnet / noch übrig
// KZ partnername-A1
// Grund VKZ nicht ändern > jährlich anpassung eintragen (VKZ für das jahr mit abzug oder auschlag)
// übernahme aus dem letzten jahr 

// Partnerseite mit Strategie für den Partner

  companiesAsString = (companies) => {
    const companiesNameArray = companies.map( (company) => { return company.name})
    console.log("companiesNameArray", companiesNameArray);
    return companiesNameArray.join(", ");
  }

  render() {
    const classes = { styles };

    return (
       <div>
       <h1>Partner Übersicht</h1>
  
       <Grid style={{textAlign: "right",marginBottom: "10px"}} item xs={12} >
        <Button component={Link} to={"/new-partner"}>
          Neuen Partner anlegen
          <AddCircleIcon style={{marginLeft:'10px'}} />
        </Button>
      </Grid>

       <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="left">Partner Name</TableCell>
            <TableCell align="left">Unternehmen</TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.partners.map(partner => (
            <TableRow key={partner.name}>
              <TableCell component="th" scope="row" align="left">
                {partner.name}
              </TableCell>

              <TableCell align="center" align="left">
                { this.companiesAsString(partner.companies)}
              </TableCell>
          
              <TableCell align="right">
                <Button component={Link} to={"/partner-admin/" + partner.id} className="yellowButtonDark">Partner bearbeiten</Button>
              </TableCell>

              <TableCell component="th" scope="row" align="left">
                <Delete item={partner} type="Partner" refetchCallBack={this.fetchData}/>
              </TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
    </div>
    );
  }
}

export default PartnerList;