import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
//import FolderIcon from '@material-ui/icons/ErrorOutlinedIcon';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { red } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import { BrowserRouter as Router,Redirect, Link } from "react-router-dom";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
//import clsx from 'clsx';
import {Timeline, TimelineEvent} from 'react-event-timeline'
import {queryActivities} from "../../../api/queryActivities";
import {queryLogs} from "../../../api/queryLogs";
import {postActivity} from "../../../api/postActivity";
import {toCurrency, slugify} from "../../utility";
import usr from "../../../api/users";
import companyApi from "../../../api/company";
import {parseQueryString} from '../../utility';

const styles = makeStyles( theme => ({
	logInBox: {
		padding:'20px',
		textAlign:'center',
		margin:'0 auto'
	},
	button: {
		background:'#303030',
		padding:'.75rem 1.5rem'
	},
	vCentered: {
		display:'flex',
		alignItems:'center',
		justifyContent:'center',
	},
	root: {
		flexGrow: 1
	},
	paper: {
		textAlign: 'center',
	},
	container: {
		background: '#000000',
	},
	tableCell: {
		fontSize: '16px',
	},
	selectField: {
		marginTop: '8px',
	}

}));

const useStyles = makeStyles( theme => ({
	saveButton: {
		position: 'fixed',
		right: 0,
		bottom: 0,
		background: '#222',
		color: '#fff',
	},
	backButton: {
		position: 'fixed',
		left: 0,
		bottom: 0,
		background: '#222',
		color: '#fff',
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
		padding: "0 5px",
		minHeight: "160px",		
		alignContent: "flex-start",
	},
	chip: {
		margin: 2,
		height: '32px',
		fontSize: '14px'
	},
	formControl: {
		margin: 0,
		width: "100%",
	}, 
	formControlSelect:{
		minHeight: "173px",		
	},
	textField:{
		width:"100%",
		maxWidth:"400px"
	}

}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

 

class AcceptInvitation extends React.Component {

	constructor(props) {
		super(props);
		console.log("UsersSingle props", props);

		var query = window.location.search.substring(1);
		const params =  parseQueryString(query);


		this.state = {
			invitationKey: params.invitationKey,
			invitation:null,
			user:{
				password:'',
				passwordRepeat:''
			},
			invitationAccepted:false,
			passwordStrength:null,
		}

		console.log(this.state);
	}

	componentDidMount(){
		
		
		console.log("AcceptInvitation mounted");
		this.fetchInvitation();
		
	}

	fetchInvitation () {
		const state = {...this.state};
		this.props.loading(true);
		usr.fetchInvitation(this.state.invitationKey)
		.then((result) => {
			console.log(result);
			if(result){
				this.setState({invitation:result});
				console.log(result);
			}
			this.props.loading(false);
		})
		.catch((error) => {
			console.log("UsersSingle Companies",error);  
			this.props.loading(false);      
		})
	}


	closeMessage = function() {
		this.setState({displayMessage: false});
		this.setState({messages: ''});
	}


	inputChangeHandler = (e) => {
		var name = e.target.name;
		var value = e.target.value;

		console.log(name, value);

		var newState = {...this.state};

		

		if (typeof newState.user[name] !== 'object') {
		    newState.user[name] = value;
		} else {
			console.log("is object");
			if(name == "role"){
				console.log(newState.userRoles, name, value);
				const roleName = newState.userRoles[value-1];
				newState.user[name].id = newState.userRoles.indexOf(roleName) + 1;
				newState.user[name].name = roleName
			}
			else if (name == "company"){
				console.log("I found it",this.findCompanyObjectId(value));
				newState.user[name]= this.findCompanyObjectId(value);
			}
		}

		newState.passwordStrength = passwordStrength(newState.user.password);
		console.log(newState);
		this.setState(newState);


		
	}

	activateUser = (e) => {
		console.log(this.state.user);
		const userFromState = this.state.user;



		if(userFromState.password !== userFromState.passwordRepeat){
			this.props.setMessage("Ihre Eingaben sind nicht gleich.");
			return;
		}

		if(!this.state.passwordStrength.strengthOk){
			this.props.setMessage("Bitte wählen Sie ein stärkeres Passwort.");
			console.log();
			return;
		}

		const user = {
			password:userFromState.password,
		}



		this.props.loading(true);
		usr.activateUser(this.state.invitationKey, user)
		.then((result) => {
			console.log(result);
			if(result){
				
				const message = (
				<SnackbarContent 
				open={this.state.displayMessage} 
				style={{background: '#ddecdf', color: '#009640'}} 
				message={<span id="message-id">Der User wurde eingeladen!</span>}
				 />
			);
			this.setState({messages: message});
			this.setState({displayMessage: true});
			this.setState({invitationAccepted:true})
			}
			this.props.loading(false);
		})
		.catch((error) => {
			this.props.loading(false);
			console.log("UsersSingle",error.response);
			const message = (
				<SnackbarContent 
				open={this.state.displayMessage} 
				style={{background: '#fedfe0', color: '#e2373d'}} 
				message={<span id="message-id">{error.response.data.message}</span>}
				 />
			);
			this.setState({messages: message});
			this.setState({displayMessage: true});  

		})
	}


	render() {

		if(this.state.invitationAccepted){
			return <Redirect to='/login'/>;
		}

		if(this.state.invitation){
	
			const classes = {styles};
			const invitation = this.state.invitation;
			const user = this.state.user;
		
	
			return(
				<div>
					      
					<form className={classes.container} noValidate autoComplete="off">
						<Grid container spacing={5} xs={6} style={{margin:"0 auto"}}>

							<Grid container item xs={12} style={{textAlign:"center", paddingBottom:0}}>
								<h1 szyle={{paddingBottom:"0"}}>Bitte wählen Sie ein Passwort.</h1>
								<p>Es sollte mindestens 8 Zeichen lang sein, sowie Groß-, Kleinbuchstaben und Zahlen einthalten.</p>
							</Grid>



							<Grid container item xs={12} style={{justifyContent: 'space-between',backgroundColor:'#eee', margin:"40px auto"}}>
								
								<Grid item xs={6}>
									<TextField
										id="password"
										label="Passwort"
										name="password"
										className={classes.textField}
										value={user.password}
										margin="normal"
										onChange={(e) => this.inputChangeHandler(e)}   
										type="password"
										style={{width:"100%",maxWidth:"400px"}}          
									/>

									
								</Grid>
								<Grid item xs={6}>
								<TextField
									id="password-repeat"
									label="Passwort Wiederholung"
									name="passwordRepeat"
									className={classes.textField}
									value={user.passwordRepeat}
									margin="normal"
									onChange={(e) => this.inputChangeHandler(e)}   
									type="password"
									style={{width:"100%",maxWidth:"400px"}}               
								/>
								</Grid>
								<Grid item xs={12} style={{marginTop:"40px"}}>
								{ this.state.passwordStrength ? (
										<p style={ {color:this.state.passwordStrength.color }}>Passwort-Stärke: {this.state.passwordStrength.strength}</p>

									) : null}
								</Grid>
								<Grid item xs={12} style={{marginTop:"40px"}}>
									<Button onClick={this.activateUser}>Speichern</Button>
								</Grid>
								
							</Grid>
						
						</Grid>
						
					</form>

					<Snackbar
		        anchorOrigin={{
		          vertical: 'bottom',
		          horizontal: 'center',
		        }}
		        open={this.state.displayMessage}
		        onClose={(e) => this.closeMessage(e)}
		        autoHideDuration={3000}
		      >
		      {this.state.messages ? this.state.messages : null}
		      </Snackbar>
		      
				</div>
			)
		}
		return null;
		
	}
		
}

const SaveButton = (props) => {
	const classes = useStyles(props);  

	return(
			<Button className={classes.saveButton} onClick={(e) => props.clickHandler(e)}>User einladen</Button>
	)
}

const BackButton = (props) => {
	const classes = useStyles(props);  
	return(
			<Button button to="/users" component={Link} className={classes.backButton}>Zurück</Button>
	)
}


const passwordStrength = (password)  =>{
                
                // Do not show anything when the length of password is zero.
                if (password.length === 0) {
                    return null;
                }
                // Create an array and push all possible values that you want in password
                var matchedCase = new Array();
                matchedCase.push("[$@$!%*#?&]"); // Special Charector
                matchedCase.push("[A-Z]");      // Uppercase Alpabates
                matchedCase.push("[0-9]");      // Numbers
                matchedCase.push("[a-z]");     // Lowercase Alphabates

                // Check the conditions
                var ctr = 0;
                for (var i = 0; i < matchedCase.length; i++) {
                    if (new RegExp(matchedCase[i]).test(password)) {
                        ctr++;
                    }
                }
                // Display it
                var color = "";
                var strength = "";
                var strengthOk=false;
                switch (ctr) {
                    case 0:
                    case 1:
                    case 2:
                        strength = "Schwach";
                        color = "red";
                        
                        break;
                    case 3:
                        strength = "Mittel";
                        color = "orange";
                        break;
                    case 4:
                        strength = "Stark";
                        color = "green";
                        strengthOk=true;
                        break;
                }
               
               return {strength:strength, color:color, strengthOk:strengthOk}
            }


export default AcceptInvitation;