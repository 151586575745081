import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: '#eee',
    marginTop: theme.spacing(8),
    padding: theme.spacing(6, 0),
  },
}));

function Footer() {

  const classes = useStyles();
  return null;

   return (
       <footer className={classes.footer}>
        <Container maxWidth="lg">
          <Typography variant="h6" align="center" gutterBottom>
            Footer
          </Typography>
          <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
            Something here to give the footer a purpose!
          </Typography>
        </Container>
      </footer>
     );
}

export default Footer;