import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { BrowserRouter as Router,Redirect, Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import { queryPartners } from '../../../api/queryPartners';
import {AppConsumer} from'../../../App';
import {toCurrency} from "../../utility";
import usr from "../../../api/users";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Grid from '@material-ui/core/Grid';
import Delete from './../../Delete';

const styles = theme => ({
  logInBox: {
    padding:'20px',
    'text-align':'center',
    margin:'0 auto'
  },
  button: {
    background:'#303030',
    padding:'.75rem 1.5rem'
  },
  vCentered: {
    display:'flex',
    'align-items':'center',
    'justify-content':'center',
  },
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

 

class UserList extends React.Component {


  constructor(props) {
    super(props);

    console.log("UserList", props);
    this.state = {
      users: [],
    }
  } 


  componentDidMount(){
    this.fetchData();
  }
 
// mp jahr (1. jahr + zeitraum / 2. jahr + zeitraum )
//zielgruppen dropdown
// aktivität dropdown
// spalten: kosten / bereits abgerechnet / noch übrig
// KZ partnername-A1
// Grund VKZ nicht ändern > jährlich anpassung eintragen (VKZ für das jahr mit abzug oder auschlag)
// übernahme aus dem letzten jahr 

// Partnerseite mit Strategie für den Partner
  
  fetchData = () => {
    this.props.loading(true);
    usr.fetchUsers()
    .then((result) => {

        //const state = {...this.state};
        //state.partners.push(partnerData);        
        //this.setState(state);
        if(result){
          console.log("UserList",result);
          this.setState({users:result});
        }
        this.props.loading(false);
        

    })
    .catch((error) => {
      console.log(error); 
      this.props.loading(false);       
    })
  }

  render() {
    const classes = { styles };

    return (
       <div>
       <h1>Users</h1>
   
       <Grid style={{textAlign: "right",marginBottom: "10px"}} item xs={12} >
        <Button component={Link} to={"/new-user/invite"}>
          User einladen
          <AddCircleIcon style={{marginLeft:'10px'}} />
        </Button>
      </Grid>

       <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="left">User Name</TableCell>
            <TableCell align="left">Partner</TableCell>
            <TableCell align="left">Unternehmen</TableCell>
            <TableCell align="left">Rolle</TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.users.map(user => (
            <TableRow key={user.username}>
              <TableCell component="th" scope="row" align="left">
                {user.username}
              </TableCell>
              <TableCell align="center" align="left">
                { (user.company) ? user.company.partner : null}
              </TableCell>
              <TableCell align="center" align="left">
                { (user.company) ? user.company.name : null}
              </TableCell>
              <TableCell align="center" align="left">
                {user.role.name ? user.role.name : ""}
              </TableCell>
              <TableCell align="right">
                <Button component={Link} to={"/users/" + user.id} className="yellowButtonDark">User Info</Button>
              </TableCell>

              <TableCell component="th" scope="row" align="left">
                <Delete item={user} type="User" refetchCallBack={this.fetchData}/>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
    </div>
    );
  }
}

export default UserList;