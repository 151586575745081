import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {AppConsumer} from '../App';



const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    'min-height':'70vh',
    'flex-direction':'column',
    'margin':'20px 0',
    width:"100vw",
    maxWidth:"100vw"
  },
}));

function Content(props) {

  const classes = useStyles();
  console.log("Content",props);

   return (
       <Container component="main" maxWidth="xl" className={classes.main}  height="100%">
       {props.children}
      </Container>
     );
}

export default Content;


