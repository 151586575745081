import axios from 'axios';
import config from './apiConfig';

const postInvoice = (invoiceID = '', invoiceData) => {
	const invoiceIdParam = invoiceID ? "/" + invoiceID : "";
	var headers = {'Authorization': "Bearer " + localStorage.getItem("unfAccessToken")};

	return new Promise(function(resolve, reject) {
		
		axios({
		    headers:headers,
		    method: 'POST',
		    url: config.baseUrl + '/api/1.1/invoice' + invoiceIdParam,
		    data: invoiceData,
		})
		.then(function (response) {
		    console.log('postInvoiceResponse',response);
		    resolve(response.data);
		})
		.catch(function (error) {
		    console.log(error);
		    reject(error);
		});
	});

}

export { postInvoice };
