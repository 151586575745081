import axios from 'axios';
import config from './apiConfig';

const queryLogs = (objectId) => {

	var headers = {'Authorization': "Bearer " + localStorage.getItem("unfAccessToken")};

	return new Promise(function(resolve, reject) {
		axios({
		    headers:headers,			
		    method: 'GET',
		    url: config.baseUrl + '/api/1.1/logs/' + objectId,
		})
		.then(function (response) {
		    resolve(response.data);
		})
		.catch(function (error) {
		    console.log(error);
		    reject(error);
		});
	});

}

export { queryLogs };
