import React from 'react';
import auth from './../api/auth';
import {AppConsumer} from '../App';
import Login from './containers/Login/Login';
import {getMeta} from './../api/meta';
import { BrowserRouter as Router,Redirect, Link, withRouter } from "react-router-dom";
interface MyProps { }


class Ccc extends React.Component {

  

  constructor(props) {
    super(props);
    console.log("Ccc",props);

    this.excludeFromAuth = [
        '/invitation/accept'
      ]

      console.log(window.location.pathname, this.excludeFromAuth);
      console.log((  this.excludeFromAuth.includes(window.location.pathname)));

    this.state = {
      authChecked:false,
      appInitialized:false,
      authNecessary: (this.excludeFromAuth.includes(window.location.pathname) ) ? false : true,
    }

    console.log(this.state);
  }

  componentDidMount(){

    if(this.state.authChecked){
      this.initApp();
    }

    if(this.state.authNecessary){
      if(!this.props.user){
        console.log("no user");

        if(localStorage.getItem("unfAccessToken")){

          console.log("acceToken exists ... trying to fetch");
          auth.whoAmI()
          .then((result) => {
            console.log("yay! User is ok... go ahead");
            console.log("whoAmI result",result);
      
            this.props.updateUser(result);
            this.setState({authChecked:true});
            console.log("set auth Checked");
            this.initApp();
          
        })
        .catch((error) => {
          this.setState({authChecked:true});
          console.log(error);
          console.log("Oh no! Token is not ok");
         //this.setState({'responseMessage':error});        
        });
        } else {
          console.log("no token");
          this.setState({authChecked:true});
        }
      }

    }
   }

   initApp = () => {

     if(!this.props.labels){
       getMeta()
       .then((result) => {
              console.log("meta fetched", result);
           if(result){
             this.props.setMeta(result);
             //this.setState({appInitialized:true});
             console.log("set appInitialized");
             this.props.setAppInitialized();
           }
            
        })
        .catch((error) => {
            console.log(error);
            console.log("Oh no! No meta");
           //this.setState({'responseMessage':error});        
          });
     } else {
       console.log("meta ok");
     }
   }


  render() {
    console.log("User Status",this.state);
      if(!this.state.authNecessary){
        console.log("User Status rendering children no auth necc");
        return (<React.Fragment>{this.props.children}</React.Fragment>);
      }

      if(!this.state.authChecked){
        console.log("User Status: return null > not ready");
        return null;
      }

      if(this.state.authChecked && !this.props.user){
        return (
           <Login 
            updateUser={this.props.updateUser}
            authStatus={this.props.authStatus}
            user={this.props.user} 
            updateUser={this.props.updateUser}
            goals={this.props.goals} 
            status={this.props.status} 
            targetGroups={this.props.targetGroups} 
            wrapper = {this.props}
            authStatus = {this.props.authStatus}
            setMeta = {this.props.setMeta}
            setAppInitialized = {this.props.setAppInitialized}

         />);
      }

      if(this.props.user){
         console.log("User Status rendering children: user ok");
        if(this.props.appInitialzed){
          console.log("User Status rendering children: appInitialized ok");
          return (<React.Fragment>{this.props.children}</React.Fragment>);
         } else {
           console.log("User Status: appInitialized not ok");
           return null;
         }
       } else {
         console.log("not inititalized",this.props.appInitialzed, this.props.user);
         // exlcude /login path, because it already renders Login
         if(window.location.pathname == '/login'){
           return null;
         }
         
         return (
           <Login 
            updateUser={this.props.updateUser}
            authStatus={this.props.authStatus}
            user={this.props.user} 
            updateUser={this.props.updateUser}
            goals={this.props.goals} 
            status={this.props.status} 
            targetGroups={this.props.targetGroups} 
            wrapper = {this.props}
            authStatus = {this.props.authStatus}
            setMeta = {this.props.setMeta}
            setAppInitialized = {this.props.setAppInitialized}

         />);
       }
  }
  
}


function UserStatus(props: React.PropsWithChildren<MyProps>) {
 return (
    <AppConsumer>
            { context => ( <Ccc 
              user={context.state.user} 
              updateUser={context.updateUser}
              goals={context.labels.goals} 
              status={context.labels.status} 
              targetGroups={context.labels.targetGroups} 
              wrapper = {props}
              authStatus = {context.state.authStatus}
              setMeta = {context.setMeta}
              labels = {context.state.labels}
              appInitialzed = {context.state.appInitialzed}
              setAppInitialized = {context.setAppInitialized}
              > 
              {props.children}
              </Ccc>
              )}
            
     </AppConsumer>
    );
}

export default withRouter(UserStatus);