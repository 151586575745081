import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
//import FolderIcon from '@material-ui/icons/ErrorOutlinedIcon';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SaveIcon from '@material-ui/icons/Save';
import { red } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import { BrowserRouter as Router,Redirect, Link } from "react-router-dom";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
//import clsx from 'clsx';
import {Timeline, TimelineEvent} from 'react-event-timeline'
import {queryActivities} from "../../../api/queryActivities";
import {queryLogs} from "../../../api/queryLogs";
import {postActivity} from "../../../api/postActivity";
import {toCurrency, slugify} from "../../utility";
import { createNewPartner} from "../../../api/partners";
import { 
	getMeta, 
	getGoals, 
	deleteGoal, 
	updateGoal, 
	newGoal,
	getActivityTypes,
	deleteActivityType,
	updateActivityType,
	newActivityType,
	getTargetGroups,
	deleteTargetGroup,
	updateTargetGroup,
	newTargetGroup,

	 } from "../../../api/meta";
import SimpleModal from './../../SimpleModal';


const styles = makeStyles( theme => ({
	logInBox: {
		padding:'20px',
		textAlign:'center',
		margin:'0 auto'
	},
	button: {
		background:'#303030',
		padding:'.75rem 1.5rem'
	},
	vCentered: {
		display:'flex',
		alignItems:'center',
		justifyContent:'center',
	},
	root: {
		flexGrow: 1
	},
	paper: {
		textAlign: 'center',
	},
	container: {
		background: '#000000',
	},
	tableCell: {
		fontSize: '16px',
	},
	selectField: {
		marginTop: '8px',
	}

}));

const useStyles = makeStyles( theme => ({
	saveButton: {
		position: 'fixed',
		right: 0,
		bottom: 0,
		background: '#222',
		color: '#fff',
	},
	backButton: {
		position: 'fixed',
		left: 0,
		bottom: 0,
		background: '#222',
		color: '#fff',
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
		padding: "0 5px",
		minHeight: "160px",		
		alignContent: "flex-start",
	},
	chip: {
		margin: 2,
		height: '32px',
		fontSize: '14px'
	},
	formControl: {
		margin: 0,
		width: "100%",
	}, 
	formControlSelect:{
		minHeight: "173px",		
	}

}));


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

 

class MetaAdmin extends React.Component {

	constructor(props) {
		super(props);
		console.log("MetaAdmin props", props);
		this.partnerId = props.wrapper.match.params.partnerId;
		this.state = {
			activityTypes:null,
			goals:null,
			targetGroups:null,
		}

		//this.deleteGoalHandler =this.deleteGoalHandler.bind(this);
		//this.inputChangeHandler = this.inputChangeHandler.bind(this);
		//this.updateGoalHandler = this.updateGoalHandler.bind(this);

	}

	componentDidMount(){
		this.fetchGoals();
		this.fetchActivityTypes();
		this.fetchTargetGroups();

	}

	fetchGoals(){
		getGoals()
			.then( (result) => {
				this.setState({goals:result});
				console.log(result);
			})
			.catch( (result) => {
				console.log(result);
			});
	}

	fetchActivityTypes(){
		getActivityTypes()
			.then( (result) => {
				this.setState({activityTypes:result});
				console.log("fetchActivityTypes",result);
			})
			.catch( (result) => {
				console.log(result);
			});
	}

	fetchTargetGroups(){
		getTargetGroups()
			.then( (result) => {
				this.setState({targetGroups:result});
				console.log("fetchTargetGroups",result);
			})
			.catch( (result) => {
				console.log(result);
			});
	}


	refetchMeta(){
		getMeta()
       .then((result) => {
              console.log("meta fetched", result);
           if(result){
             this.props.setMeta(result);
           }
            
        })
        .catch((error) => {
            console.log(error);
            console.log("Oh no! No meta");
           //this.setState({'responseMessage':error});        
          });
	}


	closeMessage = function() {
		this.setState({displayMessage: false});
		this.setState({messages: ''});
	}




	deleteGoalHandler = (item) => {

		if( window.confirm(`Möchten Sie das Ziel "${item.name}" wirklich löschen?`) ){
			console.log("deleting");
			this.props.loading(true);

			deleteGoal(item.id)
				.then( (result) => {
					this.props.loading(false);
					this.fetchGoals();
					this.refetchMeta();
				})
				.catch( (result) => {
					this.props.loading(false);
					console.log(result);
				});
		}
	}

	updateGoalHandler = (item) => {
		this.props.loading(true);

		updateGoal(item.id, {name:item.name})
			.then( (result) => {
				this.props.loading(false);
				this.fetchGoals();
				this.refetchMeta();
			})
			.catch( (result) => {
				console.log(result);
				this.props.loading(false);
			});
	}

	newGoalHandler = (name) =>{
		this.props.closeModal();
		this.props.loading(true);
		newGoal({name:name})
			.then( (result) => {
				this.props.loading(false);
				this.fetchGoals();
				this.refetchMeta();
			})
			.catch( (result) => {
				console.log(result);
				this.props.loading(false);
			});
	}


	deleteActivityTypeHandler = (item) => {

		if( window.confirm(`Möchten Sie den Aktivitätstypen "${item.name}" wirklich löschen?`) ){
			console.log("deleting");
			this.props.loading(true);

			deleteActivityType(item.id)
				.then( (result) => {
					this.props.loading(false);
					this.fetchActivityTypes();
					this.refetchMeta();
				})
				.catch( (result) => {
					this.props.loading(false);
					console.log(result);
				});
		}
	}

	updateActivityTypeHandler = (item) => {
		this.props.loading(true);

		updateActivityType(item.id, {name:item.name})
			.then( (result) => {
				this.props.loading(false);
				this.fetchActivityTypes();
				this.refetchMeta();
			})
			.catch( (result) => {
				console.log(result);
				this.props.loading(false);
			});
	}

	newActivityTypeHandler = (name) =>{
		this.props.closeModal();
		this.props.loading(true);
		newActivityType({name:name})
			.then( (result) => {
				this.props.loading(false);
				this.fetchActivityTypes();
				this.refetchMeta();
			})
			.catch( (result) => {
				console.log(result);
				this.props.loading(false);
			});
	}

	/* targetGroup Handlers */

	deleteTargetGroupHandler = (item) => {

		if( window.confirm(`Möchten Sie die Zielgruppe "${item.name}" wirklich löschen?`) ){
			console.log("deleting");
			this.props.loading(true);

			deleteTargetGroup(item.id)
				.then( (result) => {
					this.props.loading(false);
					this.fetchTargetGroups();
					this.refetchMeta();
				})
				.catch( (result) => {
					this.props.loading(false);
					console.log(result);
				});
		}
	}

	updateTargetGroupHandler = (item) => {
		this.props.loading(true);

		updateTargetGroup(item.id, {name:item.name})
			.then( (result) => {
				this.props.loading(false);
				this.fetchTargetGroups();
				this.refetchMeta();
			})
			.catch( (result) => {
				console.log(result);
				this.props.loading(false);
			});
	}

	newTargetGroupHandler = (name) =>{
		this.props.closeModal();
		this.props.loading(true);
		newTargetGroup({name:name})
			.then( (result) => {
				this.props.loading(false);
				this.fetchTargetGroups();
				this.refetchMeta();
			})
			.catch( (result) => {
				console.log(result);
				this.props.loading(false);
			});
	}

	inputChangeHandler = (e, type) => {
		
		const id = parseInt(e.target.id.replace(`${type}-`, ''));
		const newVal = e.target.value;
		const typeState = this.state[type];

		const newTypeState = typeState.map(item => {
			if(item.id == id){
				item.name = newVal;
			}
			return item;
		});

		this.setState({[type]:newTypeState});
	}




	render() {

		if(this.state.redirectTo){
			return (<Redirect to={'/partner-admin/' + this.state.redirectTo} />);
		}

			const classes = {styles};
			const goals = this.state.goals;
			const activityTypes = this.state.activityTypes;
			const targetGroups = this.state.targetGroups;
	
			return(
				<div>
					<Grid container xs={12} md={8} spacing={5} style={{margin:"40px auto", border:"1px solid #eee"}}>

						<Grid container item xs={12} style={{ display:'flex',justifyContent:'space-between', paddingBottom:0}}>
							<h2>Ziele</h2>
							<div>
								<div style={{textAlign:"right"}}>
					              <Button onClick={ () => { 

					              		this.props.setModal(<NewItemPopUp new="das Ziel" newItemHandler={this.newGoalHandler}/>);
				
					              	 }}>
					                Neues Ziel
					                <AddCircleIcon style={{marginLeft:'10px'}} />
					              </Button>
					            </div>
							</div>
							
						</Grid>
						<Grid container item xs={12}>
							{ goals ? (
								<PropList 
									type="goals" 
									data={goals} 
									new="Neues Ziel" 
									deleteItem={this.deleteGoalHandler}
									updateItem={this.updateGoalHandler}
									inputHandler={this.inputChangeHandler}
									/>
							) : (<LocalLoader/>) }
						</Grid>
					</Grid>



					<Grid container xs={12} md={8} spacing={5} style={{margin:"40px auto"}}>

						<Grid container item xs={12} style={{ display:'flex',justifyContent:'space-between', paddingBottom:0}}>
							<h2>Aktivitätstypen</h2>
							<div>
								<div style={{textAlign:"right"}}>
					              <Button onClick={ () => { 

					              		this.props.setModal(<NewItemPopUp new="den Aktivitätstypen" newItemHandler={this.newActivityTypeHandler}/>);
				
					              	 }}>
					                Neuer Aktivitätstyp
					                <AddCircleIcon style={{marginLeft:'10px'}} />
					              </Button>
					            </div>
							</div>
							
						</Grid>
						<Grid container item xs={12}>
							{ activityTypes ? (
								<PropList 
									type="activityTypes" 
									data={activityTypes} 
									new="Neues Ziel" 
									deleteItem={this.deleteActivityTypeHandler}
									updateItem={this.updateActivityTypeHandler}
									inputHandler={this.inputChangeHandler}
									/>
							) : (<LocalLoader/>) }
						</Grid>
					</Grid>

					<Grid container xs={12} md={8} spacing={5} style={{margin:"40px auto"}}>

						<Grid container item xs={12} style={{ display:'flex',justifyContent:'space-between', paddingBottom:0}}>
							<h2>Zielgruppen</h2>
							<div>
								<div style={{textAlign:"right"}}>
					              <Button onClick={ () => { 

					              		this.props.setModal(<NewItemPopUp new="der Zielgruppe" newItemHandler={this.newTargetGroupHandler}/>);
				
					              	 }}>
					                Neue Zielgruppe
					                <AddCircleIcon style={{marginLeft:'10px'}} />
					              </Button>
					            </div>
							</div>
							
						</Grid>
						<Grid container item xs={12}>
							{ targetGroups ? (
								<PropList 
									type="targetGroups" 
									data={targetGroups} 
									new="Neues Ziel" 
									deleteItem={this.deleteTargetGroupHandler}
									updateItem={this.updateTargetGroupHandler}
									inputHandler={this.inputChangeHandler}
									/>
							) : (<LocalLoader/>) }
						</Grid>
					</Grid>

					<BackButton />
	
		      <Snackbar
		        anchorOrigin={{
		          vertical: 'bottom',
		          horizontal: 'center',
		        }}
		        open={this.state.displayMessage}
		        onClose={(e) => this.closeMessage(e)}
		        autoHideDuration={3000}
		      >
		      {this.state.messages ? this.state.messages : null}
		      </Snackbar>
				</div>
			)

		

	}
		
}


class NewItemPopUp extends React.Component  {

	constructor(props){
		super(props)
		this.state = {
			name:''
		}
	}

	inputHandler = (e) => {
		this.setState({name:e.target.value});
	}

	render(){
		return (
			<React.Fragment>
				<h3>Bitte geben Sie einen Namen {this.props.new} ein</h3>
				<form onSubmit={(e) => {
					e.preventDefault();
					if(this.state.name){	
						this.props.newItemHandler(this.state.name);
					}
				}}>
				<TextField
					label="Name"
					name="name"
					margin="normal"
					value={this.state.name}
					style={{width:"80%", maxWidth:"600px"}}
					onChange={this.inputHandler}
				/>
				<div style={{marginTop:"20px"}}>
					<Button type="submit">Speichern</Button>
				</div>
				</form>
			</React.Fragment>
		);
	}
}



const PropListRow = styled.div`
	
	width:100%;

	.prop-list-row:nth-child(odd) {
		background:#fff;
	}
`;

const ButtonContainer = styled.div`
	display:flex;
	align-items:center;
	width:100%;

	@media (min-width: 800px) {
		width:auto;
	}

	.saveBtn {
		cursor:pointer;
		margin:0 10px;
	}
`;

const LocalLoaderContainer = styled.div`
	position:relative;
	width: 100%;
	height:auto;
    min-height: 90px;

	.loadingSpinner {
		position:absolute;
		top:50%;
		left:50%;
		transform:translate(-50%,-50%);
	}

`;

const LocalLoader = (props) => {
	return (
		<LocalLoaderContainer>
			<CircularProgress className="loadingSpinner"/>
		</LocalLoaderContainer>
	);
}

const PropList = (props) => {

	if(!props.data){
		return null;
	}
	console.log("PropList", props);
	const data = props.data;
	console.log(data);

	const itemList = data.map( (item, index) => ( 
		
			<Grid item xs={12} style={{justifyContent: 'space-between', display:'flex', flexWrap:'wrap',background:(index%2 == 0 ? 'rgba(0,0,0,.03)': 'transparent'), padding:"0 20px 10px 20px"}}>
		
					<TextField
						id={`${props.type}-${item.id}`}
						key={`${props.type}-${item.id}`}
						label="Name"
						name={`${props.type}-${item.id}`}
						value={item.name}
						margin="normal"
						style={{width:"80%", maxWidth:"600px"}}
						onChange={ (e) => props.inputHandler(e, props.type) }

					/>
					<ButtonContainer>
						<DeleteForeverIcon 
						className="deleteBtn"
						onClick= { () => props.deleteItem(item)}  
						/>
						<SaveIcon 
							className="saveBtn"
							onClick= { () => props.updateItem(item)}  
						/>
					</ButtonContainer>
				
			</Grid>
		) );

	return (
		<React.Fragment>
			
		<Grid container spacing={5} style={{padding:"20px"}}>
			{itemList}
		</Grid>
		</React.Fragment>
	);
	/*
	return (
		{ data.map( (item) => {
			return <div id={`${props.type}-${item.id}`}>{item.name}</div>
		}
		 )}
	);
	*/
}

const SaveButton = (props) => {
	const classes = useStyles(props);  

	return(
			<Button className={classes.saveButton} onClick={(e) => props.clickHandler(e)}>Partner Speichern</Button>
	)
}

const BackButton = (props) => {
	const classes = useStyles(props);  
	return(
			<Button button to="/partner-admin" component={Link} className={classes.backButton}>Zurück</Button>
	)
}

const prepareMultiSelectValues  = (companiesArray, selected) => {

	console.log(companiesArray, selected);

	return selected.map(selectedCompany => { 
		return companiesArray.map(function(commpanyObject, index) {
			
		  if (commpanyObject.id == selectedCompany.id){
		  	console.log("index is", index);
		  	console.log("comparing", commpanyObject.id, selectedCompany.id);
		  	return index;
		  }
		});
	});

}






export default MetaAdmin;