import axios from 'axios';
import config from './apiConfig';

const fetchUsers = () => {

	var headers = {'Authorization': "Bearer " + localStorage.getItem("unfAccessToken")};

	return new Promise(function(resolve, reject) {
		axios({
		    method: 'GET',
		    url: config.baseUrl + '/api/1.1/user' ,
		    headers:headers,
		})
		.then(function (response) {
			console.log("fetchUsers",response);
		    resolve(response.data);
		})
		.catch(function (error) {
		    console.log(error);
		    reject(error);
		});
	});
}

const fetchSingleUser = (userId) => {

	var headers = {'Authorization': "Bearer " + localStorage.getItem("unfAccessToken")};

	return new Promise(function(resolve, reject) {
		console.log(config.baseUrl + '/api/1.1/user/' + userId);
		axios({
		    method: 'GET',
		    url: config.baseUrl + '/api/1.1/user/' + userId,
		    headers:headers,
		})
		.then(function (response) {
			console.log("fetchSingleUser",response);
		    resolve(response.data);
		})
		.catch(function (error) {
		    console.log(error);
		    reject(error);
		});
	});
}

const updateSingleUser = (userId, data) => {

	var headers = {'Authorization': "Bearer " + localStorage.getItem("unfAccessToken")};

	return new Promise(function(resolve, reject) {
		console.log(config.baseUrl + '/api/1.1/user/' + userId);
		axios({
		    method: 'POST',
		    url: config.baseUrl + '/api/1.1/user/' + userId,
		    headers:headers,
		    data:data,
		})
		.then(function (response) {
			console.log("fetchSingleUser",response);
		    resolve(response.data);
		})
		.catch(function (error) {
		    console.log(error);
		    reject(error);
		});
	});
}


const inviteUser = ( data) => {

	var headers = {'Authorization': "Bearer " + localStorage.getItem("unfAccessToken")};

	return new Promise(function(resolve, reject) {
		axios({
		    method: 'POST',
		    url: config.baseUrl + '/api/1.1/user/invitation' ,
		    headers:headers,
		    data:data,
		})
		.then(function (response) {
			console.log("inviteUser",response);
		    resolve(response.data);
		})
		.catch(function (error) {
		    console.log(error);
		    reject(error);
		});
	});
}


const fetchInvitation = (invitationKey) => {


	return new Promise(function(resolve, reject) {
		axios({
		    method: 'GET',
		    url: config.baseUrl + '/api/1.1/user/invitation/' + invitationKey ,
		})
		.then(function (response) {
			console.log("fetchUsers",response);
		    resolve(response.data);
		})
		.catch(function (error) {
		    console.log(error);
		    reject(error);
		});
	});
}



const activateUser = ( invitationKey, data) => {

	var headers = {'Authorization': "Bearer " + localStorage.getItem("unfAccessToken")};

	return new Promise(function(resolve, reject) {
		axios({
		    method: 'POST',
		    url: config.baseUrl + '/api/1.1/user/invitation/' + invitationKey,
		    headers:headers,
		    data:data,
		})
		.then(function (response) {
			console.log("inviteUser",response);
		    resolve(response.data);
		})
		.catch(function (error) {
		    console.log(error);
		    reject(error);
		});
	});
}



export default {fetchUsers, fetchSingleUser, updateSingleUser, inviteUser, fetchInvitation, activateUser};