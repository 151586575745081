import React from 'react';


const styles = theme => ({
  logInBox: {
    padding:'20px',
    'text-align':'center',
    margin:'0 auto'
  },
  button: {
    background:'#303030',
    'border-radius':'1.25rem',
    padding:'.75rem 1.5rem'
  },
  vCentered: {
    display:'flex',
    'align-items':'center',
    'justify-content':'center',
  }
});



class Page_404 extends React.Component {


  constructor(props) {
    super(props);

  }



  render() {

    const { classes } = this.props;

    return (
      <h1>Page not found!</h1>
    );
  }
}

export default Page_404;