import axios from 'axios';
import config from './apiConfig';

const login = (credentials) => {

	return new Promise(function(resolve, reject) {
	  axios.post(config.baseUrl + '/api/1.1/login', credentials, {
		headers: {
			'Access-Control-Allow-Origin' : '*',
      'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
		  }
	  })
	  .then(function (response) {

	    localStorage.setItem('unfAccessToken',response.data.accessToken);
	    resolve(response);
	  })
	  .catch(function (error) {
	  	if(error.response){
	  		if(error.response.data){
	  			if(error.response.data.message){
	  				reject(error.response.data.message);
	  			}
	  		}
	  	}
	    reject("Es ist ein Fehler aufgetreten.");
	  });
	});
}

const whoAmI = () => {

	var headers = {'Authorization': "Bearer " + localStorage.getItem("unfAccessToken")};

	return new Promise(function(resolve, reject) {
		axios({
		    method: 'GET',
		    url: config.baseUrl + '/api/1.1/me' ,
		    headers:headers,
		})
		.then(function (response) {
		    resolve(response.data);
		})
		.catch(function (error) {
		    console.log(error);
		    reject(error);
		});
	});
}


const logout = () => {

	var headers = {'Authorization': "Bearer " + localStorage.getItem("unfAccessToken")};

	return new Promise(function(resolve, reject) {
		axios({
		    method: 'POST',
		    url: config.baseUrl + '/api/1.1/logout' ,
		    headers:headers,
		})
		.then(function (response) {
			localStorage.removeItem('unfAccessToken');
		    resolve(response.data);
		})
		.catch(function (error) {
		    console.log(error);
		    reject(error);
		});
	});
}

export default {whoAmI, login, logout};