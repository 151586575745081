import getRoutes from './index';
import React from 'react';
import { BrowserRouter as Router, Route, Link, withRouter, Switch } from "react-router-dom";
import Page_404 from './../components/containers/404/Page_404'


class RouteSwitch extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
    	routes:getRoutes()
    }
  }

  getContainer (container) {
  	return (container);
  }

 

  render() {
  	
    const routes = this.state.routes.map (function (v, k, a) {
		return (<Route exact={v.exact} path={v.route} component={withRouter(v.container)} key={v.route}/>);
	});

    return (
 		<React.Fragment>
 			<Switch>
    			{routes}
    	</Switch>
	    {this.props.children}
    </React.Fragment>
      
    );
  }
}

export default withRouter(RouteSwitch);