import axios from 'axios';
import config from './apiConfig';

const postActivity = (activityData) => {

	const activityID = 	activityData.id;
	const activityIdParam = activityID ? "/" + activityID : ""; 
	var headers = {'Authorization': "Bearer " + localStorage.getItem("unfAccessToken")};
	//activityData.status = 1;
	return new Promise(function(resolve, reject) {
		
		axios({
		    headers:headers,			
		    method: 'POST',
		    url: config.baseUrl + '/api/1.1/activity' + activityIdParam,
		    data: activityData,
		})
		.then(function (response) {
		    console.log('activityUpdateResponse',response);
		    resolve(response.data);
		})
		.catch(function (error) {
		    console.log(error);
		    reject(error);
		});
	});

}

export { postActivity };
