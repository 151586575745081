import axios from 'axios';
import config from './apiConfig';

const queryPlans = (planID = '', order = null) => {

	let orderParams = "";

	if(order){		
		const orderBy = order.orderBy ? `?order_by=${order.orderBy }`: "";
		const subKey = order.subKey ? `&sub_key=${order.subKey }`: "";
		const orderType = order.asc === true ? "&order=asc": "&order=desc";
		orderParams = orderBy ? `${orderBy}${subKey}${orderType}` : "";
	}

	console.log("ordering", orderParams, order);
	
	const planIDParam = planID ? "/" + planID : "";
	var headers = {'Authorization': "Bearer " + localStorage.getItem("unfAccessToken")};

	return new Promise(function(resolve, reject) {
		axios({
		    method: 'GET',
		    url: `${config.baseUrl}/api/1.1/plan${planIDParam}${orderParams}`,
		    headers:headers,
		})
		.then(function (response) {
			let marketingPlans = [];

		    !Array.isArray(response.data) ? marketingPlans.push(response.data): marketingPlans = response.data;
		    	
		    resolve(marketingPlans);
		})
		.catch(function (error) {
		    console.log(error);
		    reject(error);
		});
	});

}

export { queryPlans };
