import * as loginRoutes from '../components/containers/Login';
import * as pageRoutes from '../components/containers/Page';
import * as partnerRoutes from '../components/containers/PartnerSingle';
import * as partnerInfoRoutes from '../components/containers/PartnerInfo';
import * as mpRoutes from '../components/containers/MarketingPlan';
import * as activityRoutes from '../components/containers/Activity';
import * as userRoutes from '../components/containers/Users';
import * as partnerAdminRoutes from '../components/containers/Partners';
import * as companyAdminRoutes from '../components/containers/Companies';
import * as metaAdminRoutes from '../components/containers/MetaAdmin';
import * as noFoundRoutes from '../components/containers/404';
//import * as actions from './../store/actions'

const getRoutes = () => {
	return [].concat(
				loginRoutes.routes,
				pageRoutes.routes,
				partnerRoutes.routes,
				partnerInfoRoutes.routes,
				mpRoutes.routes,
				activityRoutes.routes,	
				userRoutes.routes,
				partnerAdminRoutes.routes,
				companyAdminRoutes.routes,
				metaAdminRoutes.routes,
				noFoundRoutes.routes // Must be Last!! switch/case/break;
			);
}
export default getRoutes;