import Page from './Page';
import React from 'react';
import {AppConsumer} from '../../../App';

export const contextWrapper = (props) =>{
  return (
    <AppConsumer>
            { context => ( <Page 
            	user={context.state.user} 
            	goals={context.state.labels.goals} 
            	status={context.state.labels.status} 
            	targetGroups={context.state.labels.targetGroups}
            	wrapper = {props} 
                loading = {context.loading}
            	/> )}
     </AppConsumer>
    );
}

export const routes = [
	{
		route:'/partner/:partnerSlug/:planId',
		exact:true,
		nicename:'Home',
		showInNav:true,
		'container':contextWrapper,
	}
];