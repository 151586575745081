import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
//import FolderIcon from '@material-ui/icons/ErrorOutlinedIcon';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { red } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import { BrowserRouter as Router,Redirect, Link } from "react-router-dom";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
//import clsx from 'clsx';
import {Timeline, TimelineEvent} from 'react-event-timeline'
import {queryActivities} from "../../../api/queryActivities";
import {queryLogs} from "../../../api/queryLogs";
import {postActivity} from "../../../api/postActivity";
import {toCurrency, slugify} from "../../utility";
import {fetchSinglePartner, updatePartner} from "../../../api/partners";
import companyApi from "../../../api/company";
import MultipleSelect from './../../MultipleSelect';

const styles = makeStyles( theme => ({
	logInBox: {
		padding:'20px',
		textAlign:'center',
		margin:'0 auto'
	},
	button: {
		background:'#303030',
		padding:'.75rem 1.5rem'
	},
	vCentered: {
		display:'flex',
		alignItems:'center',
		justifyContent:'center',
	},
	root: {
		flexGrow: 1
	},
	paper: {
		textAlign: 'center',
	},
	container: {
		background: '#000000',
	},
	tableCell: {
		fontSize: '16px',
	},
	selectField: {
		marginTop: '8px',
	}

}));

const useStyles = makeStyles( theme => ({
	saveButton: {
		position: 'fixed',
		right: 0,
		bottom: 0,
		background: '#222',
		color: '#fff',
	},
	backButton: {
		position: 'fixed',
		left: 0,
		bottom: 0,
		background: '#222',
		color: '#fff',
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
		padding: "0 5px",
		minHeight: "160px",		
		alignContent: "flex-start",
	},
	chip: {
		margin: 2,
		height: '32px',
		fontSize: '14px'
	},
	formControl: {
		margin: 0,
		width: "100%",
	}, 
	formControlSelect:{
		minHeight: "173px",		
	}

}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

 

class PartnerSingle extends React.Component {

	constructor(props) {
		super(props);
		console.log("UsersSingle props", props);
		this.partnerId = props.wrapper.match.params.partnerId;
		this.state = {
			partner: {},
			logs: [],
			messages: '',
			companies: [],
			displayMessage: false,
			userRoles:props.userRoles,

		}

	}

	componentDidMount(){
		
		

		this.fetchPartner();
		this.fetchCompanies();
		

		
	}
	fetchCompanies () {
		const state = {...this.state};
		companyApi.fetchCompanies()
		.then((result) => {
			console.log(result);
			if(result){
				this.setState({companies:result});
				console.log(this.state);
			}
		})
		.catch((error) => {
			console.log("UsersSingle Companies",error);        
		})
	}
	fetchPartner(){
		this.props.loading(true);
		const state = {...this.state};
		fetchSinglePartner(this.partnerId)
		.then((result) => {
			console.log(result);
			if(result){
				this.setState({partner:result});
			}
			this.props.loading(false);
		})
		.catch((error) => {
			console.log("UsersSingle",error);
			this.props.loading(false);      
		})
	}



	closeMessage = function() {
		this.setState({displayMessage: false});
		this.setState({messages: ''});
	}

	findCompanyObjectId (companyId) {
		const companyObj = this.state.companies.filter(obj => {
			  return obj.id === parseInt(companyId)
		});

		if(companyObj.length){
			return companyObj[0];
		}
		return null;
	}

	inputChangeHandler = (e) => {
		var name = e.target.name;
		var value = e.target.value;

		console.log(name, value);

		var newState = {...this.state};

		if (typeof newState.partner[name] !== 'object') {
		    newState.partner[name] = value;
		} else {
			console.log("is object");
			if(name == "companies"){
				console.log(newState.companies, name, value);
				newState.partner[name] = value;
			}
			
		}
		
		console.log(newState);
		this.setState(newState);
	}

	prepareCompanies = (companies) => {
		return companies.map( (companyObj) => { return companyObj.id;})
	}

	saveUser = (e) => {
		console.log(this.state.user);
		const partnerFromState = this.state.partner;

		const partner = {
			name:partnerFromState.name,
			companies: this.prepareCompanies(partnerFromState.companies),
		}


		updatePartner(this.partnerId, partner)
		.then((result) => {
			console.log(result);
			if(result){
				this.fetchPartner();
		
				this.props.setMessage("Partner wurde gespeichert!");
		
			}
		})
		.catch((error) => {
			console.log("UsersSingle",error);
			this.props.setMessage("Partner konnte nicht gespeichert werden!");     
		})
		
	}


	render() {

		if(!Object.keys(this.state.partner).length){
			return null;
		}


			const classes = {styles};
			const partner = this.state.partner;
			const {companies} = this.state;

		
	
			return(
				<div>
					      
					<form className={classes.container} noValidate autoComplete="off">
						<Grid container spacing={5} style={{justifyContent: 'center',display:'flex'}}>
							<Grid container item xs={6} style={{justifyContent: 'space-between',backgroundColor:'#eee', margin:"40px auto"}}>
								<Grid item xs={12}>
									<TextField
										id="name"
										label="Partner Name"
										name="name"
										value={partner.name}
										fullWidth
										margin="normal"
										onChange={(e) => this.inputChangeHandler(e)}
									/>
								</Grid>
								<Grid item xs={12}>
									<MultipleSelect item={partner} name="companies" label="Unternehmen" mappingData={companies} style={{marginTop: ".5rem", display: "inline-flex"}} changeHandler={(e) => this.inputChangeHandler(e)}/>				      
								</Grid>
							
							</Grid>
						
						</Grid>
						<SaveButton clickHandler={() => this.saveUser()} />
						<BackButton />
					</form>
		      <Snackbar
		        anchorOrigin={{
		          vertical: 'bottom',
		          horizontal: 'center',
		        }}
		        open={this.state.displayMessage}
		        onClose={(e) => this.closeMessage(e)}
		        autoHideDuration={3000}
		      >
		      {this.state.messages ? this.state.messages : null}
		      </Snackbar>
				</div>
			)

		

	}
		
}

const SaveButton = (props) => {
	const classes = useStyles(props);  

	return(
			<Button className={classes.saveButton} onClick={(e) => props.clickHandler(e)}>Partner Speichern</Button>
	)
}

const BackButton = (props) => {
	const classes = useStyles(props);  
	return(
			<Button button to="/partner-admin" component={Link} className={classes.backButton}>Zurück</Button>
	)
}

const prepareMultiSelectValues  = (companiesArray, selected) => {

	console.log(companiesArray, selected);

	return selected.map(selectedCompany => { 
		return companiesArray.map(function(commpanyObject, index) {
			
		  if (commpanyObject.id == selectedCompany.id){
		  	console.log("index is", index);
		  	console.log("comparing", commpanyObject.id, selectedCompany.id);
		  	return index;
		  }
		});
	});

}





export default PartnerSingle;