import axios from 'axios';
import config from './apiConfig';

export const createNewPlan = ( data) => {

	var headers = {'Authorization': "Bearer " + localStorage.getItem("unfAccessToken")};

	return new Promise(function(resolve, reject) {
		console.log(config.baseUrl + '/api/1.1/plan');
		axios({
		    method: 'POST',
		    url: config.baseUrl + '/api/1.1/plan',
		    headers:headers,
		    data:data,
		})
		.then(function (response) {
			console.log("createNewPlan",response);
		    resolve(response.data);
		})
		.catch(function (error) {
		    console.log(error);
		    reject(error);
		});
	});
}

export const updatePlan = ( planId, data) => {
	console.log(planId, data);
	var headers = {'Authorization': "Bearer " + localStorage.getItem("unfAccessToken")};

	return new Promise(function(resolve, reject) {
		console.log(config.baseUrl + '/api/1.1/plan/' + planId);
		axios({
		    method: 'POST',
		    url: config.baseUrl + '/api/1.1/plan/' + planId,
		    headers:headers,
		    data:data,
		})
		.then(function (response) {
			console.log("createNewPlan",response);
		    resolve(response.data);
		})
		.catch(function (error) {
		    console.log(error);
		    reject(error);
		});
	});
}

export default {createNewPlan, updatePlan};