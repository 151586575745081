import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {postInvoice} from './../api/postInvoice';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {toCurrency, toCurrencyNumber, localeParseFloat} from "./utility";

class NewInvoice extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			invoiceData : {
				activity_id: props.activityID,				
				voucher : '',
				date:this.formatDate(new Date),
				description : '',
				amount : '',
				status : 1,
			},
			invoice: props.invoice ? props.invoice : null,
			newInvoice: true,
		}

	}

	componentDidMount () {
		
		if(this.state.invoice){
			let inv = {
				...this.state.invoiceData,
				id: this.state.invoice.id,
				description: this.state.invoice.description,
				status: this.state.invoice.status.id,
				amount: this.state.invoice.amount,
				description: this.state.invoice.description,
				voucher: this.state.invoice.voucher,
				date: this.formatDate(
					moment(this.state.invoice.date, "DD.MM.YYYY")
				),
			};
			this.setState({invoiceData: inv, newInvoice: false});
		}

	}

	saveInvoice = function(){
		this.state.invoiceData.amount = localeParseFloat(this.state.invoiceData.amount);

		let invoiceID = '';

		if(!this.state.newInvoice){
			invoiceID = this.state.invoiceData.id;
		}

		postInvoice(invoiceID ,this.state.invoiceData)
		.then((result) =>{

			this.props.setMessage('Rechnung gespeichert!');
			this.props.reloadActivity(this.props.activityID);
			//this.props.closeModal();
		})
		.catch((error) => {
			console.log(error);
			let errorMessage = 'Rechnung konnte nicht gespeichert werden!';

			if(error.response.data.message){
				if (typeof error.response.data.message == 'object'){
					Object.keys(error.response.data.message).map(
						(key) => {
							console.log(key);
							errorMessage = errorMessage + " " + error.response.data.message[key];
						}
					);
				} else {
					errorMessage = error.response.data.message;
				}
			}

			this.props.setMessage(errorMessage);

		})
	}


	inputChangeHandler = function(e) {
		const state = {...this.state};
		let inputName = e.target.name;
		let inputValue = e.target.value;

		if( inputName == 'amount'){
			//inputValue = toCurrencyNumber(inputValue);
			//inputValue = inputValue.replace(",",".");
		}

		state.invoiceData[inputName] = inputValue;
		this.setState(state);
	}

	formatDate(date) {
	    var d = new Date(date),
	        month = '' + (d.getMonth() + 1),
	        day = '' + d.getDate(),
	        year = d.getFullYear();

	    if (month.length < 2) 
	        month = '0' + month;
	    if (day.length < 2) 
	        day = '0' + day;

	    return [year, month, day].join('-');
	}

	handleDateChange = (e) => {
		const datePicked = new Date(e);
		const startDateFormatted = this.formatDate(datePicked);

		const endDate = datePicked.setFullYear(datePicked.getFullYear() + 1);
		const endDateFormatted = this.formatDate(endDate);

		const state = {...this.state};
		state.invoiceData.date = startDateFormatted;
		this.setState(state, e=> console.log("NewInvoice",this.state));
	}


	render(){

		const {newInvoice, invoiceData} = this.state;

		return(
			<Grid container spacing={5} >
				<Grid item xs={12}>
					<h2>Rechnung { newInvoice ? `hinzufügen` : `bearbeiten`}</h2>
				</Grid>
				<Grid item xs={4}>
					<TextField
						id="voucher"
						label="Rechnungsnummer"
						name="voucher"
						value={this.state.invoiceData.voucher }
						margin="normal"
						onChange={(e) => this.inputChangeHandler(e)}                  
					/>
				</Grid>
				<Grid item xs={4}>
		
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
				        <KeyboardDatePicker
				          disableToolbar
				          variant="inline"
				          format="dd.MM.yyyy"
				          margin="normal"
				          id="date-picker-inline"
				          label="Rechnungsdatum"
				          value={this.state.invoiceData.date}
				          onChange={this.handleDateChange} 
				          KeyboardButtonProps={{
				            'aria-label': 'change date',
				          }}
				        />
			    	</MuiPickersUtilsProvider>
				</Grid>
				
				<Grid item xs={4}>
					<TextField
						id="amount"
						label="Rechnungssumme"
						name="amount"
						value={this.state.invoiceData.amount}
						margin="normal"
						onChange={(e) => this.inputChangeHandler(e)}                  
					/>
				</Grid>

			
				<Grid item xs={6}>
					<TextField
						id="status"
						name="status"
						select
						value={this.state.invoiceData.status }
						SelectProps={{native: true}}
						margin="normal"
						style={{width: '100%'}}
						onChange={(e) => this.inputChangeHandler(e)}						
					>
						{this.props.mappingData.map((obj) => <option key={`invoiceStatus-${obj.id}`} value={obj.id}>{obj.name}</option>)}
					</TextField>
				</Grid>				
				<Grid item xs={12}>	
					<Button onClick={() => this.saveInvoice()}>speichern</Button>
				</Grid>		
			</Grid>
		)
	}

}


/*
<Grid item xs={12}>
	<TextField
		id="description"
		label="Beschreibung"
		name="description"
		value={this.state.invoiceData.description }
		margin="normal"
		onChange={(e) => this.inputChangeHandler(e)}
		style={{width:"100%"}}                  
	/>
</Grid>
*/

export default NewInvoice;