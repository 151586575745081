import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Link, withRouter, Switch } from "react-router-dom";
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import theme from './theme/theme';
import Header from './components/Header';
import Footer from './components/Footer';
import Content from './components/Content';
import RouterSwitch from './routes/RouterSwitch'
import getRoutes from './routes/index';
import UserStatus from './components/UserStatus';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { makeStyles } from '@material-ui/core/styles';
import SimpleModal from './components/SimpleModal';
import CircularProgress from '@material-ui/core/CircularProgress';

const AppContext = React.createContext();

class AppProvider extends React.Component{

  constructor () {
    super()
    this.state = {
      helloProp:'',
      user:null,
      authStatus:false,
      authStatusChecked:false,
      navOpen:false,
      message: '',
      displayMessages: false,
      modalContent: '',
      modalOpen: false,
      loading:false,
      labels:false,
      appInitialzed:false,
    }
  }
  
  updateUser = (user) => {
    console.log("updateUser", {user:user, authStatus:true});
    user.hasNav = false;
    user.canAdd = false;

    if([1].indexOf(user.role.id) !== -1){
      user.canAdd = true;

    }
    if([1,3].indexOf(user.role.id) !== -1){
      user.hasNav  = true;
   }
    this.setState({user:user, authStatus:true, authStatusChecked:true});
  }

  setMessage = (message) => {
    const state = {...this.state};
    state.message = message;
    state.displayMessages = true;
    this.setState(state);
  }

  closeMessage = (e) => {
    this.setState({displayMessages: false});
  }

  setAppInitialized = (e) => {
    this.setState({appInitialzed: true});
  }

  setModal = (content) => {
    const state = {...this.state};
    state.modalContent = content;
    state.modalOpen = true;
    this.setState(state);
  }

  closeModal = (e) => {
    this.setState({modalOpen: false});
  }

  logout = () => {
    this.setState({user:null, authStatus:false, authStatusChecked:false});
  }

  loading = (state) => {
    this.setState({loading:state});
  }


  toggleNav = () => {
    console.log("opening nav");
    const currentState = this.state.navOpen;
    this.setState({navOpen:!currentState});
  }

  setMeta = (meta = null) => {
    console.log("opening nav");
    if(meta){
      this.setState({labels:meta});
    }
  }

  render(){
    return(
      <AppContext.Provider value={{ 
        state: this.state,
        updateUser:this.updateUser,
        logout:this.logout,
        toggleNav:this.toggleNav,
        setMessage: this.setMessage,
        setModal: this.setModal,
        closeModal: this.closeModal,        
        closeMessage: this.closeMessage, 
        loading:this.loading,
        setMeta:this.setMeta,
        setAppInitialized:this.setAppInitialized,
        labels:{
         /* status:[
            'beantragt',
            'freigegeben',
            'abgerechnet'
          ],*/
          /*goals:[
            'Lead-Generierung',
            'Umsatz- und Ergebnissteigerung',
            'Unternehmenswachstum',
            'Gewinnung neuer Partner',
            'Gewinnung neuer Kunden',
            'Ausbau Bestandskunden',
            'Marktsensibilisierung und Marktpräsenz für Unify-Lösungen',
            'Aus- und Weiterbildung',
            'Kompetenzentwicklung',
          ],*/
         /* targetGroups:[
            'Neukunden',
            'Bestandskunden',
            'Neue Mitarbeiter',
            'Bestehende Mitarbeiter',
          ],*/
          /*activityTypes:[
            'Enable',
            'Awareness',
            'Demand',
          ],
          userRoles:[
            'Admin',
            'Partner',
            'Manager'
          ],
          activityNames:[
            {
              "id": 1,
              "name": "Demo Equipment"
            },
            {
              "id": 2,
              "name": "Werbemittel"
            },
            {
              "id": 3,
              "name": "PR/Social Media"
            },
            {
              "id": 4,
              "name": "Events/ Messen"
            },
            {
              "id": 5,
              "name": "Telemarketing/Datenkauf"
            },
            {
              "id": 6,
              "name": "Promotion"
            },
            {
              "id": 7,
              "name": "Trainings/Zertifizierung"
            },
          ]*/
         }
      }}>
        {this.props.children}
      </AppContext.Provider>
    )

  }

}

const Loader = (props) => {
  if(props.loading){
    return (<div className="loadingOverlay">
       <CircularProgress className="loadingSpinner"/>
     </div>);
  }

   return null;
}

class App extends React.Component  {


  render() {

    return (
      <Router>
        <MuiThemeProvider theme={theme}>
          <AppProvider>
          <AppConsumer>
            { context => ( <Loader loading={context.state.loading}/> )}
         </AppConsumer>
     
              <div className="App">
                <Header/>
                <Content>
                <UserStatus>
                  <RouterSwitch />
                  </UserStatus>
   
                </Content>
                <Footer />
              </div>
              <AppConsumer>
              {context => (
                <React.Fragment>
                  <Snackbar
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    open={context.state.displayMessages}
                    onClose={(e) => context.closeMessage(e)}
                    message={<span id="message-id">{context.state.message}</span>}
                    autoHideDuration={3000}
                  >
                  </Snackbar>
                  <SimpleModal modalContent={context.state.modalContent} open={context.state.modalOpen} handleClose={context.closeModal}/>
                </React.Fragment>

                )
              }
              </AppConsumer>
          </AppProvider>
        </MuiThemeProvider>
      </Router>
    );

  }

}
export default App;

export const AppConsumer = AppContext.Consumer;
/*
<AppBar position="static">
          <div>
            <Button>
              <div>Button 1</div>
            </Button>
            <Button>
              <div>Button 2</div>
            </Button>
          </div>
        </AppBar>
        <Typography variant="h1" component="h1">Hello</Typography>
        <Typography variant="h2" component="h2">Hello</Typography>
        <Typography component="p">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</Typography>
        

 */