import React from "react";
import Drawer from "@material-ui/core/Drawer";
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { BrowserRouter as Router, Route, Link, withRouter, Switch } from "react-router-dom";

class NavDrawer extends React.Component {

  constructor (props){
    super(props);
  }
  render() {


    return (
      <Drawer
        anchor="left"
        open={this.props.drawerOpened}
        onClose={this.props.toggleDrawer}
      >
        <div
          onClick={this.props.toggleDrawer}
          onKeyDown={this.props.toggleDrawer}
          style={{minWidth:"300px"}}
        >

        
        
         <List>
            <ListItem key="Partner" style={{backgroundColor:"#212121", color:"#fff",marginTop:"-10px"}}>
            <ListItemText primary="Partner & User Verwaltung"/>
          </ListItem>
       
          <Divider />
    
          <ListItem button to="/partner-admin" component={Link} key="Partner" style={{borderBottom:"1px solid #eee"}}>
            <ListItemText primary="Partner"/>
          </ListItem>

          <ListItem button to="/company-admin" component={Link} key="Unternehmen" style={{borderBottom:"1px solid #eee"}}>
            <ListItemText primary="Unternehmen" />
          </ListItem>
   
          <ListItem button to="/users" component={Link} key="User">
            <ListItemText primary="User" />
          </ListItem>

           <ListItem button to="/fields-admin" component={Link} key="Felder & Werte">
            <ListItemText primary="Felder & Werte" />
          </ListItem>
 
          <ListItem key="Plans" style={{backgroundColor:"#212121", color:"#fff"}}>
            <ListItemText primary="Marketing Pläne" />
          </ListItem>
           <ListItem button to="/partner" component={Link} key="Partner" style={{borderBottom:"1px solid #eee"}}>
            <ListItemText primary="Partner-Übersicht" />
          </ListItem>
      </List>

      { this.props.user ? (
          <ListItem key="Partner" style={{backgroundColor:"#88c540", color:"#fff", position:"absolute", bottom:0, left:0}}>
            <ListItemText primary={this.props.user.username}/>
          </ListItem>

          ) : null}
       
        </div>
      </Drawer>
    );
  }
}

export default NavDrawer;