import axios from 'axios';
import config from './apiConfig';

export const deleteItem = (endpoint, itemId) => {

	var headers = {'Authorization': "Bearer " + localStorage.getItem("unfAccessToken")};

	return new Promise(function(resolve, reject) {
		axios({
		    method: 'DELETE',
		    url: config.baseUrl + '/api/1.1/' + endpoint + "/" + itemId ,
		    headers:headers,
		})
		.then(function (response) {
			console.log("deleteItem",response);
		    resolve(response.data);
		})
		.catch(function (error) {
		    console.log("deleteItem error", error);
		    reject(error);
		});
	});
}