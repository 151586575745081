import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import auth from './../../../api/auth';
import {getMeta} from './../../../api/meta';
import { BrowserRouter as Router, Route, Link, withRouter, Switch, Redirect } from "react-router-dom";
import { Offline, Online } from "react-detect-offline";

const styles = theme => ({
  logInBox: {
    padding:'20px',
    'text-align':'center',
    margin:'120px auto',
  },
  vCentered: {
    display:'flex',
    'align-items':'center',
    'justify-content':'center',
  },
  centered:{
    'margin':'0 auto'
  }
});




class Login extends React.Component {

  
  constructor(props) {
    super(props);
    console.log("Login",this.props);
    this.state = {
      credentials:{
        username: null,
        password: null,
      },
      responseMessage:null,

    }
  }


   initApp = () => {

     if(!this.props.labels){
       getMeta()
       .then((result) => {
              console.log("meta fetched", result);
           if(result){
             this.props.setMeta(result);
             this.props.setAppInitialized();
           }
            
        })
        .catch((error) => {
            console.log(error);
            console.log("Oh no! No meta");
           //this.setState({'responseMessage':error});        
          });
     } else {
       console.log("meta ok");
     }
   }

  changeHandler = (e) => {

    const name = e.target.name;
    const val = e.target.value
    let x = {}
    let stateUpdate = {...this.state}
    stateUpdate.credentials[name] = val;
    this.setState(stateUpdate);
  } 

  login = (e) =>{
    e.preventDefault();

    auth.login(this.state.credentials)
    .then((result) => {

      console.log(result);
      this.resetState();
      this.props.updateUser(result.data.user);
      this.initApp();
  
    })
    .catch((error) => {
      console.log(error);
       this.setState({'responseMessage':error});        
    });
  }

  resetState(){
    this.setState({
      credentials:{
        username: null,
        password: null,
      },
      responseMessage:"",
    });
  }


  render() {
     
    const { classes } = this.props;
    console.log("Login Props",this.props);

    if(this.props.authStatus){
      console.log("REDIRECTING");
      return <Redirect push to='/partner'/>;
    }

    return (
      
        <Paper className={classes.logInBox}>
        <h2>Login</h2>
          <Avatar className={classes.centered}>
            <LockOutlinedIcon />
          </Avatar>
          
          { this.state.responseMessage ? (<p style={{color:'red'}}>{this.state.responseMessage}</p>) : null }
          <Online>

            <form onSubmit={this.login}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="username">Email Addresse</InputLabel>
                <Input id="email" value={this.state.credentials.username || ''} name="username" autoComplete="email" autoFocus onChange={this.changeHandler}/>
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input name="password" value={this.state.credentials.password || ''} type="password" id="password" autoComplete="current-password" onChange={this.changeHandler}/>
              </FormControl>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.button}
                
              >
                Sign in
              </Button>
            </form>
          </Online>
          <Offline>
          <h2>Sie sind offline!</h2>
          <p>Sie können weiterarbeiten, sobald Sie wieder online sind.</p>
          </Offline>
        </Paper>
      
    );
  }
}
export default withRouter(withStyles(styles)(Login));
