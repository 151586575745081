import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { BrowserRouter as Router,Redirect, Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import { queryPartners } from '../../../api/queryPartners';
import {AppConsumer} from'../../../App';
import {toCurrency} from "../../utility";
import {fetchCompanies} from "../../../api/companies";
import Base64 from './../../base64';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Grid from '@material-ui/core/Grid';
import Delete from './../../Delete';

const styles = theme => ({
  logInBox: {
    padding:'20px',
    'text-align':'center',
    margin:'0 auto'
  },
  button: {
    background:'#303030',
    padding:'.75rem 1.5rem'
  },
  vCentered: {
    display:'flex',
    'align-items':'center',
    'justify-content':'center',
  },
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

 

class CompanyList extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      companies: [],
    }
  } 


  componentDidMount(){
     this.fetchData();
  }

  fetchData = () => {
     this.props.loading(true);
    fetchCompanies()
    .then((result) => {

        //const state = {...this.state};
        //state.partners.push(partnerData);        
        //this.setState(state);
        if(result){
          console.log("CompanyList",result);
          this.setState({companies:this.prepareCompanyIds(result)});
        }
        this.props.loading(false);

    })
    .catch((error) => {
      console.log(error);  
       this.props.loading(false);      
    })
  }

  prepareCompanyIds(companysRaw){
    return companysRaw.map( (company) => {
      const cmp = company;
      cmp.id = Base64.encode("Company:" + cmp.id);
      return cmp;
    });
  }
 
// mp jahr (1. jahr + zeitraum / 2. jahr + zeitraum )
//zielgruppen dropdown
// aktivität dropdown
// spalten: kosten / bereits abgerechnet / noch übrig
// KZ partnername-A1
// Grund VKZ nicht ändern > jährlich anpassung eintragen (VKZ für das jahr mit abzug oder auschlag)
// übernahme aus dem letzten jahr 

// Partnerseite mit Strategie für den Partner

  companiesAsString = (companies) => {
    const companiesNameArray = companies.map( (company) => { return company.name})
    console.log("companiesNameArray", companiesNameArray);
    return companiesNameArray.join(", ");
  }

  // <Button component={Link} to={"/new-partner"} className="yellowButtonDark">Neues Unternehmen anlegen</Button>

  render() {
    const classes = { styles };

    return (
       <div>
       <h1>Unternehmen</h1>

       <Grid style={{textAlign: "right",marginBottom: "10px"}} item xs={12} >
        <Button component={Link} to={"/new-company"}>
          Neue Unternehmen anlegen
          <AddCircleIcon style={{marginLeft:'10px'}} />
        </Button>
      </Grid>
       
       <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="left">Name</TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.companies.map(company => (
            <TableRow key={company.name}>
              <TableCell component="th" scope="row" align="left">
                {company.name}
              </TableCell>
            
          
              <TableCell align="right">
                <Button component={Link} to={"/company-admin/" + company.id} className="yellowButtonDark">Unternehmen bearbeiten</Button>
              </TableCell>

              <TableCell component="th" scope="row" align="left">
                <Delete item={company} type="Company" refetchCallBack={this.fetchData}/>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
    </div>
    );
  }
}

export default CompanyList;