import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import  NavDrawer from './NavigationDrawer';
import PropTypes from 'prop-types';
import * as actions from './../store/actions';
import { BrowserRouter as Router, Route, Link, withRouter, Switch } from "react-router-dom";
import Login from './containers/Login/Login';
import {AppConsumer} from'../App';
import auth from './../api/auth';
import logo from './../Mitel-Logo-RGB.jpg';



const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    'text-align':'left'
  },
  logo:{
    'width':'200px'
  },
  toolBar:{
    'justify-content':'space-between'
  }
}));


function logoutBtn(stateLogout){
  auth.logout(); stateLogout();
}



function Header (props){
  console.log("header", props);
  const classes = useStyles();
  
  var userHasNavigation = false;
   if(props.user){
     console.log();
     if ([1,3].indexOf(props.user.role.id) !== -1){
       userHasNavigation = true;
     }
   }

  return (
    <div className={classes.root}>
      <AppBar position="static">   
        <Toolbar className={classes.toolBar}>
        { (props.authStatus && userHasNavigation) ? (
          <IconButton onClick={props.toggleNav} edge="start" className={classes.menuButton} color="inherit" aria-label="Menu">
            <MenuIcon/>
          </IconButton>
          ) : null }
          <img src={logo} className={classes.logo}/>
           
          <LoginLogOutButton authStatusChecked={props.authStatusChecked} authStatus={props.authStatus} logout={props.logout}/>

        </Toolbar>
         <NavDrawer drawerOpened={props.navOpen} toggleDrawer={props.toggleNav} user={props.user}/>
      </AppBar>

      
    </div>
  );


 }


function LoginLogOutButton  (props) {

  const classes = useStyles();

  if(props.authStatusChecked){

    return (
      <React.Fragment>
      { props.authStatus ? 
            (<Button 
              color="inherit" 
              to="/login" 
              component={Link} 
              onClick={ () => logoutBtn(props.logout)} 
              className={classes.loginBtn}>LogOut</Button>
             )

            : 
            (<Button 
              color="inherit" to="/login" component={Link}  className={classes.loginBtn}>Login</Button>)
      }
      </React.Fragment>
    );
  }
  return null;
}

const contextWrapper = (props) =>{
  return (
    <AppConsumer>
            { context => ( <Header 
              user={context.state.user} 
              goals={context.labels.goals} 
              status={context.labels.status} 
              targetGroups={context.labels.targetGroups}
              wrapper = {props}
              authStatus = {context.state.authStatus}
              logout = {context.logout}
              toggleNav = {context.toggleNav}
              navOpen = {context.state.navOpen}
              authStatusChecked = {context.state.authStatusChecked}
              /> )}
     </AppConsumer>
    );
}


export default contextWrapper;