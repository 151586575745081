import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { BrowserRouter as Router,Redirect, Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
//import clsx from 'clsx';
import { loadCSS } from 'fg-loadcss';

const styles = theme => ({
  logInBox: {
    padding:'20px',
    'text-align':'center',
    margin:'0 auto'
  },
  button: {
    background:'#303030',
    padding:'.75rem 1.5rem'
  },
  vCentered: {
    display:'flex',
    'align-items':'center',
    'justify-content':'center',
  },
  root: {
    'max-width':'1280px',
    'margin':'0 auto',
  },
  paper: {
    padding: '20px',
    'text-align': 'left',
    'background':'#efefef',
    'flex-grow':1
  },
});

 

class Page extends React.Component {


  constructor(props) {
    super(props);
    this.partnerId = props.wrapper.match.params.partnerId;
    console.log(props);

    

  }

  createData(campaignType, kz, quarter, activity, descr, targetGroup, goals, cost, company, status, lastUpdated) {
    return {campaignType, kz,  quarter, activity, descr, targetGroup, goals, cost , company, status, lastUpdated};
  }

  slugify(string) {
  const a = 'àáäâãåăæąçćčđďèéěėëêęǵḧìíïîįłḿǹńňñòóöôœøṕŕřßśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;'
  const b = 'aaaaaaaaacccddeeeeeeeghiiiiilmnnnnooooooprrssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return string.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}
 

  render() {

     const classes = styles();

 

    return (
      <div className={classes.root}>
       <h1>Partner Name - Info</h1>
       <Grid container spacing={3}>
        <Grid item xs={6}>
          
          <Paper className={classes.paper} style={ classes.paper }>
          <h2>Strategie</h2>
          <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
          </Paper>
        </Grid>
        <Grid item xs={6}>
        <Paper className={classes.paper} style={ classes.paper }>
          <h2>Jahr 1</h2>
          <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
          </Paper>
        </Grid>
        <Grid item xs={6}>
        <Paper className={classes.paper} style={ classes.paper }>
          <h2>Jahr 2</h2>
          <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
          </Paper>
        </Grid>
        <Grid item xs={6}>
        <Paper className={classes.paper} style={ classes.paper }>
          <h2>Jahr 3</h2>
          <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
          </Paper>
        </Grid>
      </Grid>
    </div>
    );
  }
}

export default Page;