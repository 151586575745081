import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
//import FolderIcon from '@material-ui/icons/ErrorOutlinedIcon';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { red } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import { BrowserRouter as Router,Redirect, Link } from "react-router-dom";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
//import clsx from 'clsx';
import {Timeline, TimelineEvent} from 'react-event-timeline'
import {queryActivities} from "../../../api/queryActivities";
import {queryLogs} from "../../../api/queryLogs";
import {postActivity} from "../../../api/postActivity";
import {toCurrency, slugify} from "../../utility";
import usr from "../../../api/users";
import {fetchSingleCompany, updateSingleCompany} from "../../../api/companies";
import {fetchPartners} from "../../../api/partners";
import Base64 from './../../base64';

const styles = makeStyles( theme => ({
	logInBox: {
		padding:'20px',
		textAlign:'center',
		margin:'0 auto'
	},
	button: {
		background:'#303030',
		padding:'.75rem 1.5rem'
	},
	vCentered: {
		display:'flex',
		alignItems:'center',
		justifyContent:'center',
	},
	root: {
		flexGrow: 1
	},
	paper: {
		textAlign: 'center',
	},
	container: {
		background: '#000000',
	},
	tableCell: {
		fontSize: '16px',
	},
	selectField: {
		marginTop: '8px',
	}

}));

const useStyles = makeStyles( theme => ({
	saveButton: {
		position: 'fixed',
		right: 0,
		bottom: 0,
		background: '#222',
		color: '#fff',
	},
	backButton: {
		position: 'fixed',
		left: 0,
		bottom: 0,
		background: '#222',
		color: '#fff',
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
		padding: "0 5px",
		minHeight: "160px",		
		alignContent: "flex-start",
	},
	chip: {
		margin: 2,
		height: '32px',
		fontSize: '14px'
	},
	formControl: {
		margin: 0,
		width: "100%",
	}, 
	formControlSelect:{
		minHeight: "173px",		
	}

}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

 

class CompanySingle extends React.Component {

	constructor(props) {
		super(props);
		console.log("CompanySingle props", props);
		this.companyId = props.wrapper.match.params.companyId;
		this.state = {
			company: {},
			logs: [],
			messages: '',
			displayMessage: false,
			userRoles:props.userRoles,
			companies:[],
			partners:[],
		}

	}

	componentDidMount(){
		
		this.fetchPartners();
		this.fetchCompany();

		
	}


	fetchCompany () {
		const state = {...this.state};
		console.log("fetchSingleCompany",fetchSingleCompany);
		
		fetchSingleCompany(this.companyId)
		.then((result) => {
			console.log("fetchSingleCompany",result);
			if(result){
				this.setState({company:result});
				console.log(this.state);
			}
		})
		.catch((error) => {
			console.log("UsersSingle Companies",error);        
		})
		
	}

	fetchPartners () {
		this.props.loading(true);
	    fetchPartners()
	    .then((result) => {

	        //const state = {...this.state};
	        //state.partners.push(partnerData);        
	        //this.setState(state);
	        if(result){

	        	const partnersWithIndIDs = result.map( (partnerObj) => {
	        		const partnerIndId = Base64.decode(partnerObj.id).split(":")[1];
	        		partnerObj.id = partnerIndId;
	        		return partnerObj;

	        	});
	          console.log("fetchPartners",partnersWithIndIDs);
	          this.setState({partners:partnersWithIndIDs});
	          this.props.loading(false);
	        }
	        

	    })
	    .catch((error) => {
	      console.log(error); 
	      this.props.loading(false);      
	    })
		
	}


	closeMessage = function() {
		this.setState({displayMessage: false});
		this.setState({messages: ''});
	}

	findPartnerId (partnerId) {
		const partnerObj = this.state.partners.filter(obj => {
			console.log("checking", partnerId, obj);
			  return parseInt(obj.id) === parseInt(partnerId)
		});

		if(partnerObj.length){
			return partnerObj[0].id;
		}
		return null;
	}


	inputChangeHandler = (e) => {
		var name = e.target.name;
		var value = e.target.value;

		console.log(name, value);

		var newState = {...this.state};
		console.log(newState);

		if(name == "partner"){
			name = "partner_id";
			newState.company.partner_id = this.findPartnerId(value);
			console.log("value",value);
			console.log("findPartnerId",this.findPartnerId(value));
		}

		if (typeof newState.company[name] !== 'object') {
		    newState.company[name] = value;
		} else {
			console.log("is object");
			if(name == "role"){
				console.log(newState.userRoles, name, value);
				const roleName = newState.userRoles[value-1];
				newState.company[name].id = newState.userRoles.indexOf(roleName) + 1;
				newState.company[name].name = roleName
			}
			else if (name == "company"){
				console.log("I found it",this.findCompanyObjectId(value));
				newState.company[name]= this.findCompanyObjectId(value);
			}
		}
		
		console.log(newState);
		this.setState(newState);
	}

	saveUser = (e) => {
		console.log(this.state.company);
		const companyFromState = this.state.company;

		const company = {
			name:companyFromState.name,
			partner_id:companyFromState.partner_id,
		}

		updateSingleCompany(this.companyId, company)
		.then((result) => {
			console.log(result);
			if(result){
				this.fetchCompany();
				this.props.setMessage("Unternehmen wurde gespeichert!");
			}
		})
		.catch((error) => {
			console.log("UsersSingle",error);
			this.props.setMessage("Unternehmen konnte nicht gespeichert werden!");  
		})


	}


	render() {

		if(!Object.keys(this.state.company).length){
			return null;
		}


			const classes = {styles};
			const company = this.state.company;
			const partners = this.state.partners;
	
		
	
			return(
				<div>
					      
					<form className={classes.container} noValidate autoComplete="off">
						<Grid container spacing={5} style={{justifyContent: 'center',display:'flex'}}>
							<Grid container item xs={6} style={{justifyContent: 'space-between',backgroundColor:'#eee', margin:"40px auto"}}>
						
								<TextField
									id="name"
									label="Name"
									name="name"
									className={classes.textField}
									value={company.name}
									margin="normal"
									onChange={(e) => this.inputChangeHandler(e)}                  
								/>

							</Grid>
						
							<Grid container item  style={{justifyContent:'flex-start'} }>
								<Grid item xs={6} style={ {padding:'0 20px 0 0',justifyContent:'flex-start'} }>
									<Paper className={classes.root} style={{width:'100%', marginTop: '48px'}}>
										
									</Paper>
								</Grid>
								
							</Grid>
						</Grid>
						<SaveButton clickHandler={() => this.saveUser()} />
						<BackButton />
					</form>
		      <Snackbar
		        anchorOrigin={{
		          vertical: 'bottom',
		          horizontal: 'center',
		        }}
		        open={this.state.displayMessage}
		        onClose={(e) => this.closeMessage(e)}
		        autoHideDuration={3000}
		      >
		      {this.state.messages ? this.state.messages : null}
		      </Snackbar>
				</div>
			)

		

	}
		
}

const SaveButton = (props) => {
	const classes = useStyles(props);  

	return(
			<Button className={classes.saveButton} onClick={(e) => props.clickHandler(e)}>Unternehmen Speichern</Button>
	)
}

const BackButton = (props) => {
	const classes = useStyles(props);  
	return(
			<Button button to="/company-admin" component={Link} className={classes.backButton}>Zurück</Button>
	)
}



export default CompanySingle;