import MetaAdmin from './MetaAdmin';
import React from 'react';
import {AppConsumer} from '../../../App';

export const contextWrapper = (props) =>{
  return (
    <AppConsumer>
            { context => ( <MetaAdmin 
            	user={context.state.user} 
            	goals={context.state.labels.goals} 
            	setModal={context.setModal}
            	closeModal={context.closeModal}
            	status={context.state.labels.status} 
            	targetGroups={context.state.labels.targetGroups}
            	wrapper = {props}
            	loading = {context.loading}
            	authStatus = {context.state.authStatus}
            	setMeta = {context.setMeta}
            	/> )}
     </AppConsumer>
    );
}

export const routes = [
	{
		route:'/fields-admin',
		exact:true,
		nicename:'Fields',
		showInNav:true,
		'container':contextWrapper,
	}
];