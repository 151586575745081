import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';

const styles = makeStyles( theme => ({
	logInBox: {
		padding:'20px',
		textAlign:'center',
		margin:'0 auto'
	},
	button: {
		background:'#303030',
		padding:'.75rem 1.5rem'
	},
	vCentered: {
		display:'flex',
		alignItems:'center',
		justifyContent:'center',
	},
	root: {
		flexGrow: 1
	},
	paper: {
		textAlign: 'center',
	},
	container: {
		background: '#000000',
	},
	tableCell: {
		fontSize: '16px',
	},
	selectField: {
		marginTop: '8px',
	}

}));

const useStyles = makeStyles( theme => ({
	saveButton: {
		position: 'fixed',
		right: 0,
		bottom: 0,
		background: '#222',
		color: '#fff',
	},
	backButton: {
		position: 'fixed',
		left: 0,
		bottom: 0,
		background: '#222',
		color: '#fff',
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
		padding: "0 5px",
		minHeight: "160px",		
		alignContent: "flex-start",
	},
	chip: {
		margin: 2,
		height: '32px',
		fontSize: '14px'
	},
	formControl: {
		margin: 0,
		width: "100%",
	}, 
	formControlSelect:{
		minHeight: "173px",		
	}

}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

/*
const findIndexById  = (mappingData, singleObject) => {

	console.log("findIndexById",mappingData, singleObject);

	var index = mappingData.map( mapObj => { 

		  if (mapObj.id == singleObject.id){
		  	console.log("index is", index);
		  	console.log("comparing", mapObj.id, singleObject.id);
		  	return index;
		  }
	});

}

const findObjectByIndex = (mappingData, index) => {
	return mappingData[index];
}

const prepareMapping = (name, mapping) => {

}
*/

function valueSelected (e, changeHandler, options) {
	console.log(e, changeHandler, options);
	let inputName = e.target.name;
	let selected = e.target.value;
	console.log(inputName, selected);
	console.log("valueSelected",getSelectedObjectsByName (selected, options));
	e.target.value = getSelectedObjectsByName (selected, options);
	console.log("e.target.value", e.target.value);
	changeHandler(e);
}

function getSelectedObjectsByName (selected, options) {
	console.log("getSelectedObjectsByName", selected, options);
	return options.filter( (obj) =>{
		return selected.indexOf(obj.name) >= 0;
	});
}

const MultipleSelect = function(props) {
		const inputChangeHandler = props.changeHandler;
		const classes = useStyles(props);  
		const targetGroups = props.targetGroups;
		const item = props.item;

		const optionsAvailable = props.mappingData.map((obj) => {
			return obj.name;
		});

		const selectedValues = item[props.name].map( (obj)  => {
			return obj.name;
		});

		console.log("options",optionsAvailable);

		return(
			<FormControl style={props.style} className={classes.formControl}>
				<InputLabel htmlFor="select-multiple-chip">{props.label}</InputLabel>
				<Select
					className={classes.formControlSelect}	      
					multiple
					value={ selectedValues }
					name={props.name}
					onChange={ (e) => valueSelected(e, inputChangeHandler,  props.mappingData)}
					input={<Input id="select-multiple-chip" />}
					renderValue={selected => (
						<div className={classes.chips}>
							{selected.map( ( selectedObj, index ) => (
								<Chip key={props.name+"-sel-"+ index} label={selectedObj} className={classes.chip}/>
							))}
						</div>
					)}
					MenuProps={MenuProps}					          
					>					          
					{optionsAvailable.map((option, index) => (
						<MenuItem key={props.name+"-opt-"+index} value={option} style={{fontSize: '15px'}}>
							{option}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		)
}

export default MultipleSelect;