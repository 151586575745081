import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {postInvoice} from './../api/postInvoice';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import Base64 from './base64';
import {createNewPlan, updatePlan} from './../api/plans';


class NewPlan extends React.Component {

	constructor(props) {
		super(props);
		console.log(props);
		this.partnerIntId = Base64.decode(props.partnerId).split(":")[1];


		this.state = {
			plan : {
				partner_id: this.partnerIntId,				
				year : 1,
				startDate : moment().format(),
				endDate : '',
				vkzOrigin : 0,
			},
			isNewPlan: (this.props.planId) ? false : true,
		}

		console.log("isNewPlan",this.state.isNewPlan, this.props.planId);

		console.log(this.state);
	}

	componentDidMount() {
		if(this.props.plan){
			this.setState({plan:this.props.plan});
		}

	}
	

	inputChangeHandler = (e) => {
		console.log(e);
		const state = {...this.state};
		let inputName = e.target.name;
		let inputValue = e.target.value;
		console.log(inputName,inputValue);
		if( inputName == 'vkzOrigin'){
			inputValue = inputValue.replace(",",".");
		}
		state.plan[inputName] = inputValue;
		this.setState(state);
		console.log(state);
	}

	formatDate(date) {
	    var d = new Date(date),
	        month = '' + (d.getMonth() + 1),
	        day = '' + d.getDate(),
	        year = d.getFullYear();

	    if (month.length < 2) 
	        month = '0' + month;
	    if (day.length < 2) 
	        day = '0' + day;

	    return [year, month, day].join('-');
	}

	handleDateChange = (e) => {
		const datePicked = new Date(e);
		const startDateFormatted = this.formatDate(datePicked);

		const endDateTS = datePicked.setFullYear( datePicked.getFullYear() + 1);
		const endDate = new Date(endDateTS);
		endDate.setDate( endDate.getDate() - 1);
		const endDateFormatted = this.formatDate(endDate);

		const state = {...this.state};
		state.plan.startDate = startDateFormatted;
		state.plan.endDate = endDateFormatted;
		this.setState(state);
	}

	savePlanClicked = () => {
		
		if(this.state.isNewPlan){
			createNewPlan( this.state.plan)
			.then((result) =>{
				console.log(result);
				this.props.refetchData();
				this.props.setMessage('Markting Plan gespeichert!');
		
			})
			.catch((error) => {
				console.log(error);
				let errorMessage = 'Markting Plan konnte nicht gespeichert werden!';

				if(error.response.data.message){
					if (typeof error.response.data.message == 'object'){
						Object.keys(error.response.data.message).map(
							(key) => {
								console.log(key);
								errorMessage = errorMessage + " " + error.response.data.message[key];
							}
						);
					} else {
						errorMessage = error.response.data.message;
					}
				}

				this.props.setMessage(errorMessage);
			});
		} else {

			updatePlan(this.state.plan.id, this.state.plan)
			.then((result) =>{
				console.log(result);
				this.props.refetchData();
				this.props.setMessage('Markting Plan gespeichert!');
		
			})
			.catch((error) => {
				console.log(error);
				let errorMessage = 'Markting Plan konnte nicht gespeichert werden!';

				if(error.response.data.message){
					if (typeof error.response.data.message == 'object'){
						Object.keys(error.response.data.message).map(
							(key) => {
								console.log(key);
								errorMessage = errorMessage + " " + error.response.data.message[key];
							}
						);
					} else {
						errorMessage = error.response.data.message;
					}
				}

				this.props.setMessage(errorMessage);
			});
		}
	}


	render(){

		const plan = this.state.plan;
		return(
			<Grid container spacing={5} >
				<Grid item xs={12}>
					<h2>Marketing Plan {this.state.isNewPlan ? "hinzufügen" : "bearbeiten"}</h2>
				</Grid>
				<Grid item xs={4}>
					<TextField
						id="year"
						name="year"
						select
						label="Jahr"
						value={plan.year}
						SelectProps={{
							native: true,
								MenuProps: {
							},
						}}
						margin="normal"
						onChange={(e) => this.inputChangeHandler(e)}
					>
					{[1,2,3].map((value) => <option key={`year-${value}`} value={value}>{value + ". Jahr"}</option>)}
					</TextField>
				</Grid>

				<Grid item xs={8}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
			        <KeyboardDatePicker
			          disableToolbar
			          variant="inline"
			          format="dd.MM.yyyy"
			          margin="normal"
			          id="date-picker-inline"
			          label="Datum Beginn"
			          value={plan.startDate}
			          onChange={this.handleDateChange}
			          KeyboardButtonProps={{
			            'aria-label': 'change date',
			          }}
			        />
			    </MuiPickersUtilsProvider>
				</Grid>

		
				<Grid item xs={6}>
					<TextField
						id="vkzOrigin"
						label="VKZ Planungssumme"
						name="vkzOrigin"
						value={plan.vkzOrigin}
						margin="normal"
						onChange={(e) => this.inputChangeHandler(e)}                  
					/>
				</Grid>	
				{plan.year == 1 && 		
					<Grid item xs={6}>
						<TextField
							id="vkzDeltaPrev"
							label="VKZ Delta des alten Vertrags"
							name="vkz_delta_input"
							value={plan.vkz_delta_input || 0}
							margin="normal"
							onChange={(e) => this.inputChangeHandler(e)}                  
						/>
					</Grid>		
				}		
				<Grid item xs={12}>	
					<Button onClick={() => this.savePlanClicked()}>speichern</Button>
				</Grid>		
			</Grid>
		)
	}

}

export default NewPlan;