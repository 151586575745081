import axios from 'axios';
import config from './apiConfig';

export const fetchPartners = () => {

	var headers = {'Authorization': "Bearer " + localStorage.getItem("unfAccessToken")};

	return new Promise(function(resolve, reject) {
		axios({
		    method: 'GET',
		    url: config.baseUrl + '/api/1.1/partner' ,
		    headers:headers,
		})
		.then(function (response) {
			console.log("fetchPartners",response);
		    resolve(response.data);
		})
		.catch(function (error) {
		    console.log(error);
		    reject(error);
		});
	});
}

export const fetchSinglePartner = (partnerId) => {

	var headers = {'Authorization': "Bearer " + localStorage.getItem("unfAccessToken")};

	return new Promise(function(resolve, reject) {
		console.log(config.baseUrl + '/api/1.1/partner/' + partnerId);
		axios({
		    method: 'GET',
		    url: config.baseUrl + '/api/1.1/partner/' + partnerId,
		    headers:headers,
		})
		.then(function (response) {
			console.log("fetchSinglePartner",response);
		    resolve(response.data);
		})
		.catch(function (error) {
		    console.log(error);
		    reject(error);
		});
	});
}


export const updatePartner = (partnerId, data) => {

	var headers = {'Authorization': "Bearer " + localStorage.getItem("unfAccessToken")};

	return new Promise(function(resolve, reject) {
		console.log(config.baseUrl + '/api/1.1/partner/' + partnerId);
		axios({
		    method: 'POST',
		    url: config.baseUrl + '/api/1.1/partner/' + partnerId,
		    headers:headers,
		    data:data,
		})
		.then(function (response) {
			console.log("updatePartner",response);
		    resolve(response.data);
		})
		.catch(function (error) {
		    console.log(error);
		    reject(error);
		});
	});
}

export const createNewPartner = (data) => {

	var headers = {'Authorization': "Bearer " + localStorage.getItem("unfAccessToken")};

	return new Promise(function(resolve, reject) {
		console.log(config.baseUrl + '/api/1.1/partner');
		axios({
		    method: 'POST',
		    url: config.baseUrl + '/api/1.1/partner',
		    headers:headers,
		    data:data,
		})
		.then(function (response) {
			console.log("createNewPartner",response);
		    resolve(response.data);
		})
		.catch(function (error) {
		    console.log(error);
		    reject(error);
		});
	});
}

/*
const inviteUser = ( data) => {

	var headers = {'Authorization': "Bearer " + localStorage.getItem("unfAccessToken")};

	return new Promise(function(resolve, reject) {
		axios({
		    method: 'POST',
		    url: config.baseUrl + '/api/1.1/user/invitation' ,
		    headers:headers,
		    data:data,
		})
		.then(function (response) {
			console.log("inviteUser",response);
		    resolve(response.data);
		})
		.catch(function (error) {
		    console.log(error);
		    reject(error);
		});
	});
}


const fetchInvitation = (invitationKey) => {


	return new Promise(function(resolve, reject) {
		axios({
		    method: 'GET',
		    url: config.baseUrl + '/api/1.1/user/invitation/' + invitationKey ,
		})
		.then(function (response) {
			console.log("fetchUsers",response);
		    resolve(response.data);
		})
		.catch(function (error) {
		    console.log(error);
		    reject(error);
		});
	});
}



const activateUser = ( invitationKey, data) => {

	var headers = {'Authorization': "Bearer " + localStorage.getItem("unfAccessToken")};

	return new Promise(function(resolve, reject) {
		axios({
		    method: 'POST',
		    url: config.baseUrl + '/api/1.1/user/invitation/' + invitationKey,
		    headers:headers,
		    data:data,
		})
		.then(function (response) {
			console.log("inviteUser",response);
		    resolve(response.data);
		})
		.catch(function (error) {
		    console.log(error);
		    reject(error);
		});
	});
}
*/


export default {fetchPartners};