import React from 'react';
import {AppConsumer} from '../App';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SimpleModal from './SimpleModal';
import HelpIcon from '@material-ui/icons/Help';
import Button from '@material-ui/core/Button';
import Base64 from './base64';
import {deleteItem} from './../api/deleteItem';

class Delete extends React.Component {

	constructor (props) {
		super(props);
		console.log("Delete",props);

		this.typeMapper = {
			'Partner':{
				name:'Partner',
				'article':'den',
				'endpoint':'partner',
				'notice':'Alle dem Partner zugeordneten Marketingpläne, Aktivitäten und Rechnungen werden damit gelöscht.'
			},
			'Company':{
				'article':'das',
				name:'Unternehmen',
				'endpoint':'company',
				'notice':null
			},
			'User':{
				'article':'den',
				name:'User',
				'endpoint':'user',
				'notice':null
			},
			'Plan':{
				'article':'den',
				name:'Marketing Plan',
				'endpoint':'plan',
				'notice':'Alle dem Marketing Plan zugeordneten Aktivitäten und Rechnungen werden damit gelöscht.'
			},
			'Activity':{
				'article':'die',
				name:'Aktivität',
				'endpoint':'activity',
				'notice':'Alle der Aktivität zugeordneten Rechnungen werden damit gelöscht.'
			},
			'Invoice':{
				'article':'die',
				name:'Rechnung',
				'endpoint':'invoice',
				'notice':null
			}
		};


		this.state = {
			item:this.props.wrapper.item,
			typeMap:this.getTypeData(),
		}

		

		console.log(this.state);

	}

	getTypeData () {
		if(this.props.wrapper.type){
			if (this.props.wrapper.type in this.typeMapper) {
				return this.typeMapper[this.props.wrapper.type];
			}
		}
		return null;
	}

	deleteItemClicked = (e) => {

		deleteItem( this.state.typeMap.endpoint, this.state.item.id)
			.then((result) => {
				console.log("deleteItemClicked: then");
	          console.log("deleteItemClicked",result);
	     

	          if(result.message){
	          	this.props.setMessage(result.message);
	          }

	        	this.props.wrapper.refetchCallBack();
        		this.props.setMessage("Element wurde gelöscht.");
        		this.props.closeModal();

	    })
	    .catch((error) => {
	    	console.log("deleteItemClicked: catch", error);
	      console.log(error.response); 
	   
	      if(error.response){
	      	if(error.response.data){
	      		if(error.response.data.message){
	          	this.props.setMessage(error.response.data.message);
	          	return;
	          }
	      	}
	       }

	       this.props.setMessage('Es ist ein Fehler aufgetreten.');    
	    })
	}

	deleteClicked = (e) =>{

		if(!this.state.typeMap){
			this.props.setModal(
				(<div style={{textAlign:'center',padding:'20px'}}>
					<p style={{textAlign:'center',fontWeight:'bold'}}>
					Es ist ein fehler aufgetreten.
					</p>
					</div>)
			);
			return;
		}
		const jsx = (
			<div style={{textAlign:'center',padding:'20px'}}>
			<HelpIcon style={{width:'2em',height:'2em',opacity:.6}}/>
			<p style={{textAlign:'center',fontWeight:'bold'}}>
			{	"Sind Sie sich sicher, dass Sie " + this.state.typeMap.article + " " + this.state.typeMap.name + " löschen möchten?"}
			</p>
			<p>{this.state.typeMap.notice}</p>
			<div style={{
				display:'flex',
				justifyContent:'space-between', 
				maxWidth:'300px',
				margin:'0 auto',
				padding:'20px 0'
			}}>
			 <Button className="button--dark" onClick={this.deleteItemClicked}>Ja, löschen</Button>
			 <Button className="button--dark" onClick={this.props.closeModal}>Nein</Button>
			</div>
			</div>
		);
		this.props.setModal(jsx);
	}

	render(){

		return (
			<DeleteForeverIcon 
				className="deleteBtn"
				onClick={this.deleteClicked}
			/>
		);
	}

}

const contextWrapper= (props) =>{
  return (
    <AppConsumer>
        { context => ( <Delete 
                user={context.state.user} 
                goals={context.labels.goals} 
                status={context.labels.status} 
                targetGroups={context.labels.targetGroups}
                wrapper = {props}
                userRoles={context.labels.userRoles}
                loading = {context.loading}
                setMessage = {context.setMessage} 
                setModal = {context.setModal}
                closeModal = {context.closeModal}
            /> )}
     </AppConsumer>
    );
}

export default contextWrapper;