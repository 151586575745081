import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components'
import { BrowserRouter as Router,Redirect, Link, withRouter } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Chart } from "react-google-charts";
import {queryPlans} from "../../../api/queryMarketingPlans";
import {toCurrency, toCurrencyNumber, slugify} from "../../utility";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Delete from './../../Delete';

const styles = theme => ({
  logInBox: {
    padding:'20px',
    'text-align':'center',
    margin:'0 auto'
  },
  button: {
    background:'#303030',
    padding:'.75rem 1.5rem'
  },
  vCentered: {
    display:'flex',
    'align-items':'center',
    'justify-content':'center',
  },
  root: {
    flexGrow: 1
  },
  paper: {
    background: '#990000',
    padding: theme.spacing(2),
    'text-align': 'left',
    color: theme.palette.text.secondary,
  },
  left:{
    'text-align':'left'
  },
  right:{
    'text-align':'right'
  },
  topInfo:{
    background:'#efefef'
  },
  green:{
    color: '#00ee00',
  }
});

const useStyles = makeStyles({
  freigegeben: {
    color: '#009900',
  },
  beantragt: {
    color: '#eeaa00',
  },  
  abgerechnet: {
    color: '#1dace9',
  },
  backButton: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    background: '#222',
    color: '#fff',
  },
});

class PartnerSingle extends React.Component {

  constructor(props) {
    console.log("PartnerSingle", props);
    super(props);
    this.partnerSlug = props.wrapper.match.params.partnerSlug;
    this.planId = props.wrapper.match.params.planId    
    this.reorderActivites = this.reorderActivites.bind(this)

    this.state = {
      marketingPlans: [],
      currentOrder:{
        orderBy:'status', 
        subKey:null, 
        asc: true,
      }
    }
    
  }

 
  componentDidMount(){
   this.fetchData();
  }


  fetchData = ( order = null ) => {

    if(!order){
      order = this.state.currentOrder;
    } else {
      this.setState({currentOrder:order});
    }

     this.props.loading(true);
        const state = {...this.state};
        queryPlans(this.planId, order)
        .then((result) => {
          if(result && result.length){
             this.setState({marketingPlans: []})
             this.setState({marketingPlans: result});
          }
          this.props.loading(false);
        })
        .catch((error) => {
          console.log(error);
          this.props.loading(false);      
        })

  }

  reorderActivites ( order ) {

    let newCurrentOrder;
    
    if(this.state.currentOrder.orderBy !== order.orderBy){
      newCurrentOrder = {... this.state.currentOrder, asc: true, ...order}
    } else {
      newCurrentOrder = {... this.state.currentOrder, asc: !this.state.currentOrder.asc}
    }
    
    this.fetchData(newCurrentOrder)
  }

  render() {

    if(this.state.marketingPlans){
      return (
        <div>
        
          {
            this.state.marketingPlans.map( plan => <MarketingPlan reorderActivites={this.reorderActivites} fetchData={this.fetchData} plan={plan} user={this.props.user} partnerSlug={this.partnerSlug}/>)
          }
        </div>
      );   
    }
    return null;
    
  }
}

const loadActivitiesChartData = (planActivities = []) => {

  let activitiesReturn = [['Task', 'Hours']];
  planActivities.map(activity => {
    activitiesReturn.push([ activity.name , activity.cost ])
  });

  return activitiesReturn;
}



const MarketingPlan = (props) => {

  const classes = { styles };
  let plan = props.plan;
  const negativeDelta = (plan.vkzDelta < 0) ? true : false;

  return(
    <React.Fragment>
      <Grid container spacing={2} style={{border:'1px solid #efefef',margin:'20px 0','min-height':'200px'}}>
        <Grid item xs={12} style={classes.paper}>
          <h2>{plan.name}</h2>
          <h3>{plan.year}. Jahr</h3>
          <p>{plan.startDate} - {plan.endDate}</p>
        </Grid>

        <Grid item xs={12} container style={{padding:'20px'}}>
          <Grid item md={3} >

            <Chart
              width={'400px'}
              height={'300px'}

              chartType="PieChart"
              loader={<div>Loading Chart</div>}
              data={[
                ['Budget', 'Euro'],
                ['VKZ geplant', plan.vkzPlanned],
                ['VKZ ungeplant', plan.vkzUnplanned >= 0 && plan.vkzUnplanned || 0],
              ]}
              formatters={[
                {
                  type: 'NumberFormat',
                  column: 1,
                  options: {
                    suffix: ' €',
                    decimalSymbol: ',',
                    groupingSymbol: '.',
                    fractionDigits: '2',
                  },
                },
              ]}
              options={{
                title: 'VKZ gesamt:' + toCurrency(plan.vkzTotal),
              }}
              rootProps={{ 'data-testid': '1' }}
            />
            { negativeDelta ? <strong style={{display: "block", marginBottom: '1.5rem'}}>VKZ Gesamt wurde bereits voll aufgebraucht und um <span style={{color: "red"}}>{toCurrency(plan.vkzUnplanned)}</span> überschritten</strong> : ''}

            <Table className={classes.table} style={{marginBottom:"40px", width:"400px"}}>
 
          <TableBody>
            <TableRow style={{background:"transparent"}}>
               <TableCell style={{border:"none", padding:0, width:"280px"}} align="left"><strong>VKZ Planungssumme:</strong></TableCell>
               <TableCell style={{border:"none", padding:0, width:"280px"}} align="left">{toCurrency(plan.vkzOrigin)}</TableCell>
            </TableRow>
            { plan.remainingDelta ? (
              <TableRow style={{background:"transparent"}} >
               <TableCell style={{border:"none", padding:0, width:"280px"}}  align="left"><strong>VKZ Übertrag (Vorjahr):</strong></TableCell>
               <TableCell style={{border:"none", padding:0, width:"280px"}}  align="left">{toCurrency(plan.remainingDelta)}</TableCell>
            </TableRow>
            ) : null}
            
            <TableRow style={{background:"transparent"}} >
               <TableCell style={{borderBottom:"none", borderTop:"1px solid #aaa", padding:0, width:"280px"}}  align="left"><strong>VKZ gesamt:</strong></TableCell>
               <TableCell style={{borderBottom:"none", borderTop:"1px solid #aaa", padding:0, width:"280px"}}  align="left">{toCurrency(plan.vkzTotal)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>

         <Table className={classes.table}style={{width:"400px"}}>
        <TableBody>
            <TableRow style={{background:"transparent"}}>
               <TableCell style={{border:"none", padding:0, width:"300px"}}  align="left"><strong>VKZ geplant:</strong></TableCell>
               <TableCell style={{border:"none", padding:0, width:"300px"}}  align="left">{toCurrency(plan.vkzPlanned)}</TableCell>
            </TableRow>

            <TableRow style={{background:"transparent"}}>
               <TableCell style={{border:"none", padding:0, width:"300px"}}  align="left"><strong>VKZ ungeplant:</strong></TableCell>
               <TableCell style={{border:"none", padding:0, width:"300px"}}  align="left">{toCurrency(plan.vkzUnplanned)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>

     
          </Grid>

          <Grid item md={3} >
            <Chart
              width={'400px'}
              height={'300px'}
              chartType="PieChart"
              loader={<div>Loading Chart</div>}
              data={loadActivitiesChartData(plan.costByActivityType)}
              options={{
                title: 'Budget nach Aktivitätstyp',
              }}
              formatters={[
                {
                  type: 'NumberFormat',
                  column: 1,
                  options: {
                    suffix: ' €',
                    decimalSymbol: ',',
                    groupingSymbol: '.',
                    fractionDigits: '2',
                  },
                },
              ]}
              rootProps={{ 'data-testid': '1' }}
            />
            <ul style={{'list-style-type':'none','text-align':'left'}}>
              {plan.costByActivityType.map(activity => <li><strong>{activity.name}</strong>: {toCurrency(activity.cost)}</li>)}
            </ul>
          </Grid>

          <Grid item md={3}>

            { negativeDelta ? null : (
            <Chart
              width={'400px'}
              height={'300px'}
              chartType="PieChart"
              loader={<div>Loading Chart</div>}
              data={[
                ['Budget', 'Value'],
                ['VKZ Delta', plan.vkzDelta],
                ['Abgerechnet', plan.vkzBilled],
              ]}
              options={{
                title: 'Abgerechnet',
              }}
              rootProps={{ 'data-testid': '1' }}
              formatters={[
                {
                  type: 'NumberFormat',
                  column: 1,
                  options: {
                    suffix: ' €',
                    decimalSymbol: ',',
                    groupingSymbol: '.',
                    fractionDigits: '2',
                  },
                },
              ]}
            />

            ) }
            
            <ul style={{'list-style-type':'none','text-align':'left'}}>
              <li><strong>VKZ abgerechnet:</strong> {toCurrency(plan.vkzBilled)}</li>
              <li><strong>VKZ Delta:</strong> {toCurrency(plan.vkzDelta)}</li>
              { negativeDelta ? (
                <li><strong>Überplanung:</strong> { toCurrencyNumber( (plan.vkzBilled / plan.vkzTotal)*100) } %</li>
              ) : null }
            </ul>
          </Grid>

          <Grid item md={3} >
            <InvoiceList plan={plan}/>
          </Grid>

        </Grid>
      </Grid>
      <Grid style={{textAlign: "right",marginBottom: "10px"}} item xs={12} >

      { props.user.canAdd ? (
            <div style={{textAlign:"right", padding:"20px 0"}}>
              <Button component={Link} to={"/new-activity/?planId=" + encodeURIComponent(plan.id) + "&partnerId=" + encodeURIComponent(props.partnerSlug)}>
                Neue Aktivität
                <AddCircleIcon style={{marginLeft:'10px'}} />
              </Button>
            </div>

           ) : null}
        
      </Grid>
      <Paper className={classes.root}>
        <Table className={classes.table} >
          <TableHead>
            <TableRow>
              <TableCell align="center"
              style={{color:"#000", fontWeight:"bold", textDecoration:"underline", cursor:"pointer"}}
              onClick={e => props.reorderActivites({orderBy:"campaign_type"})}
              >Type</TableCell>
             
              <TableCell align="center">Quartal/Jahr</TableCell>
              <TableCell align="center">Aktivität</TableCell>
              <TableCell align="center">Beschreibung</TableCell>
              <TableCell align="center">Zielgruppen</TableCell>
              <TableCell align="center">Ziele</TableCell>
              <TableCell align="center"
              onClick={e => props.reorderActivites({orderBy:"cost"})}
              style={{color:"#000", fontWeight:"bold", textDecoration:"underline", cursor:"pointer"}}
              >Kosten</TableCell>
              <TableCell align="center" 
              style={{color:"#000", fontWeight:"bold", textDecoration:"underline", cursor:"pointer"}}
              onClick={e => props.reorderActivites({orderBy:"billed"})}>Abgerechnet</TableCell>
              <TableCell align="center" 
              style={{color:"#000", fontWeight:"bold", textDecoration:"underline", cursor:"pointer"}}
              onClick={e => props.reorderActivites({orderBy:"remainingBudget"})}>Restbudget</TableCell>
              <TableCell align="center" 
              style={{color:"#000", fontWeight:"bold", textDecoration:"underline", cursor:"pointer"}}
              onClick={e => props.reorderActivites({orderBy:"company"})}>Unternehmen</TableCell>
              <TableCell align="center" 
              style={{color:"#000", fontWeight:"bold", textDecoration:"underline", cursor:"pointer"}}
              onClick={e => props.reorderActivites({orderBy:"status"})}>Status</TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <PlanActivities user={props.user} fetchData={props.fetchData} activities={plan.activities} planId={plan.id} partnerSlug={props.partnerSlug}/>
          </TableBody>
        </Table>
      </Paper>
      <BackButton linkTo={"/partner/" + props.partnerSlug}/>
    </React.Fragment>
  )
}

const BackButton = (props) => {
  const classes = useStyles(props);  
  return(
      <Button component={Link} to={props.linkTo} className={classes.backButton}>Zurück</Button>
  )
}



const PlanActivities = (props) => {
  const activities = props.activities;
  const classes = useStyles(props);  
  const typeColors = {
    Demand:'#3365cc',
    Enable:'#dc3911',
    Awareness: '#fc9900',
  };

  if(activities.length){
    return (
      activities.map(activity => ( 
        <TableRow key={activity.name.name}>
          <TableCell style={{color: typeColors[activity.campaign_type.name], fontWeight:"bold"}} align="center">{activity.campaign_type.name}</TableCell>
          <TableCell align="center">{activity.time_period}</TableCell>
          <TableCell align="center">{activity.name.name}</TableCell>
          <TableCell align="left">{activity.description}</TableCell>
          <TableCell align="center">{activity.target_groups.map(group => (<div>{group.name}</div>))}</TableCell>
          <TableCell align="center">{activity.goals.map(goals => (<div>{goals.name}</div>))}</TableCell>
          <TableCell align="center">{toCurrency(activity.cost)}</TableCell>
          <TableCell align="center">{toCurrency(activity.billed)}</TableCell>
          <TableCell align="center">{toCurrency(activity.remainingBudget)}</TableCell>
          <TableCell align="center">{activity.company.name}</TableCell>
          <TableCell align="center" className={`${classes[activity.status.name]}`}>{activity.status.name}</TableCell>
          <TableCell align="right">
            {props.user.canAdd && <Button component={Link} to={"/partner/" + props.partnerSlug + "/" + props.planId + "/" + activity.id} className="yellowButtonDark">Bearbeiten</Button> }
          </TableCell>
           <TableCell component="th" scope="row" align="left">
              {props.user.canAdd &&
                    <Delete item={activity} type="Activity" refetchCallBack={props.fetchData}/>
              }
              </TableCell>
        </TableRow>
      ))
    )

  }
  return(
    <TableRow>
      <TableCell align="center">-</TableCell>
      <TableCell align="center">-</TableCell>
      <TableCell align="center">-</TableCell>
      <TableCell align="center">-</TableCell>
      <TableCell align="center">-</TableCell>
      <TableCell align="center">-</TableCell>
      <TableCell align="center">-</TableCell>
      <TableCell align="center">-</TableCell>
      <TableCell align="center">-</TableCell>
      <TableCell align="center">-</TableCell>
      <TableCell align="center">-</TableCell>
      <TableCell align="center">-</TableCell>
    </TableRow>
  )

}

const InvoiceListWrapper = styled.div`

.invoice {
  display: flex;
  justify-content: space-between;

  &:nth-child(odd){
    background: #fafafa;
  }

  > div {
    padding: 3px 10px;
  }
}

`

function InvoiceList (props){

  if(!props.plan){ return null; }
  const activities = props.plan.activities;
  let invoices = {};

  const allInvoices = activities.map( a => { 
    
    if(a.invoices.length){

      a.invoices.map ( i => { 

        if( invoices[i.voucher] ){

          invoices[i.voucher].amount = invoices[i.voucher].amount + i.amount;
          invoices[i.voucher].items.push(i)

        } else {

          invoices[i.voucher] = {
            voucher: i.voucher,
            amount: i.amount,
            items: [i]
          }

        }
      })
    }
     
  })
  
  return (
    <InvoiceListWrapper>
      { Object.keys(invoices).map( key => {
        console.log(invoices);
        const i = invoices[key];
        return (
          <div className="invoice">
            <div>{key}</div>
            <div>{toCurrency(i.amount)}</div>
          </div>
        )

       })}
    </InvoiceListWrapper>)
}

export default withRouter(PartnerSingle);